import React, { useState } from 'react';
import { X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { ItemProps } from '../DropdownWIthMultiCheckbox';
import {
    resetClusterFilters,
    resetFilters,
    resetWatchlistFilters,
    saveClusterFilters,
    saveFilters,
    saveSelectedFilters,
    saveWatchlistFilters
} from '../../../../pages/Room/store';
import { DateRangeProps } from '../DatePicker';
import { RootState } from '../../../../store';
import { color } from '../../../../utils/getColors';
import { handleDateRange } from '../../FiltersLayout';
import { getRoom } from '../../../../utils/variables';
import { truncateString } from '../../../../utils/text';

const keys: any = {
    dateRange: 'dateRange',
    Sources: 'contentType',
    Languages: 'languages',
    Keywords: 'keywords',
    Actors: 'from',
    Sentiment: 'sentiment',
    ids: 'ids',
    Labels: 'labels',
    ThreatType: 'threatTypes',
    LocationMentions: 'origin',
    Entities: 'entities',
    Hashtags: 'hashtags',
    Mentions: 'mentions'
};

type SelectedFilterProps = {
    filter: string,
    items?: ItemProps[],
    oldFilterData?: ItemProps[],
    date?: DateRangeProps,
    setFilterName?: (e: string) => void,
    display: boolean,
    contentIds: string[],
    dateCheck: boolean,
    watchlist: boolean,
    startDate: number,
    clusters: boolean,
    boolean: boolean
};
export const SelectedFilter = ({ filter, items, oldFilterData, date, setFilterName, dateCheck,
    display, contentIds, watchlist = false, clusters = false, boolean = false }: SelectedFilterProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const allFiltersList = useSelector((state: RootState) => state.selectedFilters.filters);
    const room = getRoom();
    const [expand, setExpand] = useState(false);
    const pathname = history?.location.pathname;
    const isNarrative = pathname?.includes('/narrative/') || false;
    const clearFilters = (itemId?: string, clearAll = false) => {
        if (filter !== 'Date' && oldFilterData) {
            let noneSelected = true;
            const updatedData = oldFilterData.map(item => ({
                ...item,
                selected: (clearAll || (itemId && item.id === itemId)) ? false : item.selected
            })).filter((a) => a.selected);
            const updatedFilters = { ...allFiltersList, [keys[filter]]: updatedData.map((a) => a.id), ids: [] };
            Object.values(updatedFilters).forEach((value) => {
                if (Array.isArray(value)) {
                    if (value.length > 0) { noneSelected = false; }
                }
            });
            if (!dateCheck) { noneSelected = false; }
            dispatch(saveSelectedFilters({
                [keys[filter]]: updatedData.length ? updatedData.map((a) => a.id) : []
            }));

            if (noneSelected && setFilterName) {
                setFilterName('Filters');
                if (watchlist) {
                    return dispatch(resetWatchlistFilters()); }
                if (clusters) {
                    return dispatch(resetClusterFilters());
                } if (!boolean) { return dispatch(resetFilters({ roomId: room?.id })); }
            } if (display) {
                if (filter === 'Sources') {
                    if (!watchlist) {
                        dispatch(handleReturnReduxFuntion(clusters)({
                            contentType: updatedData.length ? updatedData.map((a) => a.id) : [],
                            sourcesWithDomains: updatedData.find((item: ItemProps) => item.selected)
                                ? updatedData.filter((item: ItemProps) => item.selected).map((
                                    item: ItemProps
                                ) => ({ key: item.id, label: item.name })) : [],
                        }));
                    } else {
                        dispatch(saveWatchlistFilters({
                            contentType: updatedData.length ? updatedData.map((a) => a.id) : undefined,
                            sources: updatedData.length ? updatedData.map((a) => a.id) : undefined,
                            sourcesWithDomains: updatedData.find((item: ItemProps) => item.selected)
                                ? updatedData.filter((item: ItemProps) => item.selected).map((
                                    item: ItemProps
                                ) => ({ key: item.id, label: item.name })) : undefined,
                        }));
                    }
                } else if (filter === 'Labels') {
                    if (!watchlist) {
                        dispatch(handleReturnReduxFuntion(clusters)({
                            labels: updatedData.length ? updatedData.map((a) => a.id) : [],
                            ids: updatedData.find((item: ItemProps) => item.selected)
                                ? updatedData.filter((item: ItemProps) => item.selected)
                                    .map((item: ItemProps) => item.contentIds?.flat())
                                    .flat() : []
                        }));
                    }
                    else {
                        dispatch(saveWatchlistFilters({
                            labels: updatedData.length ? updatedData.map((a) => a.id) : undefined,
                            ids: updatedData.find((item: ItemProps) => item.selected)
                                ? updatedData.filter((item: ItemProps) => item.selected)
                                    .map((item: ItemProps) => item.contentIds?.flat())
                                    .flat()
                                    .filter(a => a && (!contentIds || contentIds.includes(a))) : contentIds,
                        }));
                    }
                } else if (!watchlist) {
                    dispatch(handleReturnReduxFuntion(clusters)({
                        [keys[filter]]: updatedData.length ? updatedData.map((a) => a.id) : [],
                    })); } else {
                    dispatch(saveWatchlistFilters({
                        [keys[filter]]: updatedData.length ? updatedData.map((a) => a.id) : undefined,
                    }));
                }
            }
        } else {
            let noneSelected = true;
            const returnedFilters = { ...allFiltersList, dateRangeData: [] };
            Object.values(returnedFilters).forEach((value) => {
                if (Array.isArray(value)) {
                    if (value.length > 0) { noneSelected = false; }
                }
            });
            dispatch(saveSelectedFilters({
                dateRange: { startDate: 0, endDate: 0 }
            }));
            if (display) {
                dispatch(saveFilters({
                    date: { startDate: 0, endDate: 0 },
                    dateRange: handleDateRange({ startDate: 0, endDate: 0 }, room.start_date),
                }));
            }
            if (noneSelected && setFilterName) {
                setFilterName('Filters');
                if (watchlist) {
                    return dispatch(resetWatchlistFilters()); }
                if (clusters) {
                    return dispatch(resetClusterFilters());
                }
                return dispatch(resetFilters({ roomId: room?.id }));
            }
        }
    };

    const filterName = (filter.includes('Type')) ? filter.replace('Type', ' type')
        : filter.replace('LocationMentions', 'Location mentions');

    return (
        <div key={filter} className="d-flex border rounded mb-1 pl-1 justify-content-between">
            <div className="d-flex align-items-center">
                <span>{filterName.charAt(0).toUpperCase() + filterName.slice(1)}</span>
                {(!date && items) ? (
                    <div className="d-flex flex-wrap pr-1 selected-filters scrollbar-small">
                        {items.map((item, index) => {
                            if (!expand && index >= 6) { return null; }
                            return (
                                <div key={item.id} className="tag d-flex align-items-center ml-1 mb-1 mt-1">
                                    <span>{truncateString(item.name, 8, false)}</span>
                                    {!isNarrative && (
                                        <div className="d-flex align-items-center pl-1 cursor-pointer"
                                            data-testid={`x-icon-${item.id}`}
                                            onClick={() => clearFilters(item.id)}
                                        >
                                            <X color={color.blue[500]} />
                                        </div>
                                    )}
                                </div>
                            ); })}
                        {(items.length > 6 && !expand)
                         && <Button className="pr-0" onClick={() => setExpand(true)} color="link">See more</Button>}
                        {(expand && items.length > 6)
                        && <Button color="link" className="pr-0" onClick={() => setExpand(false)}>See less</Button>}
                    </div>
                ) : (
                    <div className="d-flex flex-wrap pr-1">

                        {(date?.startDate && date?.endDate)
                        && (
                            <div key={filter} className="tag d-flex align-items-center ml-1 mb-1 mt-1">
                                <span>{`${moment.unix(date.startDate).format('DD/MM/YYYY')}-
                            ${moment.unix(date.endDate).format('DD/MM/YYYY')}`}
                                </span>
                                {!isNarrative && (
                                    <div className="d-flex align-items-center pl-1 cursor-pointer"
                                        data-testid={`x-icon-${filter}`}
                                        onClick={() => clearFilters(filter)}
                                    >
                                        <X color={color.blue[500]} />
                                    </div>
                                )}
                            </div>
                        )}

                    </div>
                )}
            </div>
            {(items && items.length > 1 && !date && !isNarrative) && (
                <div className="d-flex align-items-center p-1 border-left">
                    <X color={color.blue[500]} className="cursor-pointer" onClick={() => clearFilters(undefined, true)} />
                </div>
            )}
        </div>
    ); };

export const handleReturnReduxFuntion = (clusters = false) => {
    if (clusters) {
        return saveClusterFilters;
    }
    return saveFilters;
};

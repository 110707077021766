import React from 'react';
import { Col, Row, Table } from 'reactstrap';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import ReactWordcloud, { OptionsProp } from 'react-wordcloud';
import { truncateString } from '../../../../../utils/text';
import { DuplicatedDocsModal } from '../../DuplicatedDocsModal';
import { EmptyState } from '../../../../../components/EmptyState';
import { Keyphrase } from '../../types';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { color } from '../../../../../utils/getColors';
import { Images } from '../../../../../utils/modeSwitch';

type TopKeywordsProps = {
    topKeywords: Keyphrase[];
}

const options = {
    colors: [color.blue[500]],
    enableTooltip: false,
    fontFamily: 'nunito',
    fontSizes: [20, 40],
    fontStyle: 'normal',
    fontWeight: '800',
    rotations: 0,
    padding: 0,
    rotationAngles: [0, 90],
    scale: 'linear',
    spiral: 'rectangular',
    transitionDuration: 1000,
    deterministic: true,
} as OptionsProp;

export const TopKeywords = ({ topKeywords }: TopKeywordsProps) =>
{
    const { setModal } = useModal();
    const openDuplictedDocsModal = (contentIds: string[], keywordName: string) => setModal({
        component: <DuplicatedDocsModal contentIds={contentIds} title={keywordName} />,
        goToPreviousOnClose: true,
        size: 'xl'
    });
    const callbacks = {
        onWordClick: (wordCloudItem: any) => {
            setModal({
                size: 'xl',
                component: <DuplicatedDocsModal contentIds={wordCloudItem.contentIds} title={wordCloudItem.keyword} />
            });
        }
    };
    return (
        <div className="bg-white pl-2 pr-3 py-2 selected-cluster-height scrollbar-small overflow-y-auto overflow-x-hidden">
            {topKeywords
                ? (
                    <Row>
                        <Col xs="auto" md={6}>
                            <Table hover className="border-left border-right border-bottom">
                                <thead>
                                    <tr>
                                        <th>Keywords</th>
                                        <th>Contents</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        topKeywords.map((keywords) => (
                                            <tr key={keywords.keyword}>
                                                <td className="w-75 text-primary cursor-pointer"
                                                    onClick={() => openDuplictedDocsModal(keywords.contentIds, keywords.keyword)}
                                                >
                                                    {keywords.keyword}
                                                </td>
                                                <td>{keywords.contentIds.length}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs="auto" md={6} className="h-25 cluster-wordcloud">
                            <ReactWordcloud options={options}
                                callbacks={callbacks}
                                words={topKeywords.map((item) => ({
                                    text: truncateString(item.keyword, 40),
                                    value: item.contentIds.length,
                                    original: item.keyword,
                                    ...item
                                }))}
                            />
                        </Col>
                    </Row>
                )
                : <div className="border m-2"><EmptyState title="No keywords found" icon={Images().overview} /></div>}
        </div>
    );
};

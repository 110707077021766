import React from 'react';
import { Table } from 'reactstrap';
import { Actor } from '../../types';
import { DuplicatedDocsModal } from '../../DuplicatedDocsModal';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';

type TopActorsProps = {
    topActors: Actor[]
}

export const TopActors = ({ topActors }: TopActorsProps) => {
    const { setModal } = useModal();
    const openDuplictedDocsModal = (contentIds: string[], actorName: string) => setModal({
        component: <DuplicatedDocsModal contentIds={contentIds} title={actorName} />,
        goToPreviousOnClose: true,
        size: 'xl'
    });
    const actors = topActors.filter(({ actor }) => actor);
    if (!actors?.length) {
        return <div className="border m-2"><EmptyState title="No data found" icon={Images().overview} /></div>;
    }
    return (
        <div className="bg-white px-2 pt-2 pb-1 w-100 selected-cluster-height scrollbar-small overflow-y-auto overflow-x-hidden">
            <Table hover className="border-left border-right border-bottom w-50">
                <thead>
                    <tr>
                        <th>Actor details</th>
                        <th>Contents</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        actors.map((actorData) => (
                            <tr key={actorData.actor}>
                                <td className="w-75 text-primary cursor-pointer"
                                    onClick={() => openDuplictedDocsModal(actorData.contentIds, actorData.actor)}
                                >
                                    {actorData.actor}
                                </td>
                                <td>{actorData.contentIds.length}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    );
};

import React, { forwardRef, useState } from 'react';
import { TimeBar } from 'regraph';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, DebouncedFunc, isEqual } from 'lodash';
import { useTimeline } from './useTimeline';
import { convertESInterval } from '../../../../../utils/convertESInterval';
import { NetworksHistoryStore, setRegraphLoading, updateNetworks } from '../../store';
import { color } from '../../../../../utils/getColors';
import { saveFilters, saveSelectedFilters } from '../../../store';
import { useContentFilters } from '../../../useFilters';
import { checkAndGetUnix } from '../../../../../utils/getTimeFrom';
import { getRoom } from '../../../../../utils/variables';

type TimeRange = {
    start: Date | number;
    end: Date | number;
}

export const NetworksTimeline = forwardRef((_, ref) => {
    const { loading, data, interval } = useTimeline();
    const dispatch = useDispatch();
    const room = getRoom();
    const intervalInMilliseconds = convertESInterval(interval) as number;
    const { chartState } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);
    const { filters } = useContentFilters({
        useGlobalFilters: true
    });
    const allDates = data.map(({ key }) => [key, key + intervalInMilliseconds]).flat().sort((a, b) => a - b);
    const defaultDateRange = allDates.length ? {
        start: new Date(allDates[0]),
        end: new Date(allDates[allDates.length - 1])
    } : undefined;

    const dateRange = (filters.date?.startDate && filters.date?.endDate) ? {
        start: new Date(checkAndGetUnix(filters.date.startDate) * 1000),
        end: new Date(checkAndGetUnix(filters.date.endDate) * 1000)
    } : defaultDateRange;

    if (loading) return null;

    const timeBarOptions = {
        style: {
            color: color.blue[500],
            hoverColor: '#328bfa'
        },
        backgroundColor: color.grey[100],
        sliders: {
            color: 'rgb(248, 248, 248, 0.9)',
            lineColor: color.grey[300]
        },
        zoom: {
            max: {
                unit: intervalInMilliseconds > (1000 * 60 * 60 * 24) ? 'week' : 'day' as ZoomUnits
            }
        }
    };
    const applyDateRange = (range: TimeRange) => {
        dispatch(setRegraphLoading(true));
        const dateRangeToStore = {
            dateRange: {
                startDate: checkAndGetUnix(range.start),
                endDate: checkAndGetUnix(range.end)
            },
            roomId: room?.id
        };
        dispatch(saveFilters(dateRangeToStore));
        dispatch(saveSelectedFilters(dateRangeToStore));
        dispatch(
            updateNetworks({
                chartState: {
                    ...chartState,
                    positions: {}
                }
            })
        );
    };
    let debounceRange = null as null | DebouncedFunc<any>;
    return (
        <div className="border-top">
            <MemoizedTimeBar options={timeBarOptions}
                items={Object.fromEntries(data.map(({ key, count }) => ([key, {
                    times: [{
                        time: {
                            start: key,
                            end: key + intervalInMilliseconds
                        },
                        value: count
                    }]
                }])))}
                onChange={({ range }) => {
                    if (range) {
                        if (debounceRange) debounceRange.cancel();
                        debounceRange = debounce(() => {
                            if (!isEqual(range, dateRange)) {
                                applyDateRange(range);
                            }
                        }, 500); // allow some time for user to stop using timebar
                        debounceRange();
                    }
                }}
                range={dateRange}
            />
        </div>
    );
});

const MemoizedTimeBar = React.memo(({
    options,
    items,
    onChange,
    range
}: TimeBar.Props) => {
    const [initialLoad, setInitialLoad] = useState(true);
    return (
        <TimeBar options={options}
            items={items}
            style={{ height: 100 }}
            onChange={(change) => {
                if (initialLoad) {
                    setInitialLoad(false);
                } else if (onChange) {
                    onChange(change);
                }
            }}
            range={(range?.start && range?.end) ? range : undefined}
        />
    );
}, (prevProps, nextProps) => isEqual(prevProps.items, nextProps.items) && isEqual(prevProps.range, nextProps.range));

type ZoomUnits = 'year' | 'month' | 'week' | 'day' | 'hour';

export const standardTemplate = {
    data: [
        {
            id: 'sourcesList',
            type: 'horizontalBar',
            x: 0,
            y: 0,
            w: 24,
            h: 6,
            minH: 6,
            minW: 12,
            maxH: 6,
            maxW: 24
        },
        {
            x: 0,
            y: 6,
            w: 10,
            h: 50,
            minH: 14,
            minW: 8,
            maxH: 60,
            maxW: 24,
            id: 'b',
            type: 'matches',
            data: {}
        },
        {
            x: 0,
            y: 50,
            w: 12,
            h: 26,
            minH: 26,
            minW: 9,
            maxH: 28,
            maxW: 24,
            id: 'c',
            type: 'termAnalysis'
        },
        {
            x: 8,
            y: 76,
            w: 8,
            h: 29,
            minH: 14,
            minW: 6,
            maxH: 32,
            maxW: 12,
            id: 'topPublishers',
            type: 'table'
        },
        {
            x: 10,
            y: 6,
            w: 14,
            h: 35,
            minH: 25,
            minW: 12,
            maxH: 36,
            maxW: 24,
            id: 'totalMatchesAndThreatsOverTime',
            type: 'multiSmallCharts',
            data: {
                items: [
                    { type: 'theme_matches', id: 'iusfg7w8grhfuwb', title: 'Total Matches' },
                    { type: 'high_threats', id: 'nbe9fdughw9rbwb', title: 'Total Threats' }
                ]
            }
        },
        {
            x: 10,
            y: 35,
            w: 7,
            h: 15,
            minH: 14,
            minW: 5,
            maxH: 22,
            maxW: 12,
            id: 'currentSentiment',
            type: 'pie'
        },
        {
            x: 17,
            y: 35,
            w: 7,
            h: 15,
            minH: 14,
            minW: 6,
            maxH: 32,
            maxW: 12,
            id: 'locationMentions',
            type: 'table'
        },
        {
            x: 12,
            y: 42,
            w: 12,
            h: 26,
            minH: 14,
            minW: 5,
            maxH: 30,
            maxW: 24,
            id: 'h',
            type: 'wordcloud'
        },
        {
            x: 0,
            y: 82,
            w: 12,
            h: 28,
            minH: 14,
            minW: 6,
            maxH: 34,
            maxW: 24,
            id: 'topSharedUrls',
            type: 'table'
        },
        {
            x: 0,
            y: 76,
            w: 8,
            h: 29,
            minH: 14,
            minW: 6,
            maxH: 32,
            maxW: 12,
            id: 'topSources',
            type: 'table'
        },
        {
            x: 16,
            y: 76,
            w: 8,
            h: 29,
            minH: 14,
            minW: 6,
            maxH: 32,
            maxW: 12,
            id: 'topLanguages',
            type: 'table'
        }
    ]
};

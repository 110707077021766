import React, { Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { SituationRoom } from './pages/Room';
import { useUser } from './context/user/UserContext';
import { routes } from './routes';
import { Loading } from './components/Loading';
import { SignUp } from './pages/SignUp';
import { Admin } from './pages/Admin';
import { Profile } from './pages/Profile';
import { Team } from './pages/Team';
import { RoomEditor } from './pages/SituationRooms/RoomEditor';
import { SituationRooms } from './pages/SituationRooms';
import { CreateCustomNarrative } from './pages/Room/CustomNarratives/Overview/CreateCustomNarrative';
import { ManageSavedItems } from './components/NewFilters/ManageSavedItems';

export const LoggedInApp = () => {
    const { user, notAllowed } = useUser();

    if (!user) return <Redirect to="/login" />;

    return (
        <div className="content-page d-flex flex-column">
            <Switch>
                <Suspense fallback={<Loading relative height={500} />}>
                    <Route exact path={routes.loggedIn.base}>
                        <Redirect to={routes.loggedIn.situationRooms} />
                    </Route>
                    <Route path={routes.loggedIn.situationRooms} exact>
                        <SituationRooms />
                    </Route>
                    <Route path={routes.loggedIn.situationRoomCreate} exact>
                        <RoomEditor />
                    </Route>
                    <Route path={routes.loggedIn.situationRoomDraftCreate}>
                        <RoomEditor draft />
                    </Route>
                    <Route path={routes.loggedIn.situationRoomDraftEdit}>
                        <RoomEditor draft edit />
                    </Route>
                    <Route path={routes.loggedIn.situationRoomEdit}>
                        <RoomEditor edit />
                    </Route>
                    <Route path={routes.admin.base}>
                        {user.uid && <Admin />}
                    </Route>
                    {!notAllowed.includes('teamPage') && (
                        <Route path={routes.loggedIn.teams}>
                            <Team />
                        </Route>
                    )}
                    <Route path={routes.loggedIn.signUpInvite}>
                        <SignUp />
                    </Route>
                    <Route path={routes.loggedIn.profile}>
                        <Profile />
                    </Route>
                    <Route path={routes.situationRoom.base}>
                        <SituationRoom />
                    </Route>
                    <Route path={routes.loggedIn.createCustomNarrative} exact>
                        <CreateCustomNarrative />
                    </Route>
                    <Route path={routes.loggedIn.manageSavedFilters} exact>
                        <ManageSavedItems />
                    </Route>
                    <Route path={routes.loggedIn.manageSavedBoolean} exact>
                        <ManageSavedItems />
                    </Route>
                </Suspense>
            </Switch>
        </div>
    );
};

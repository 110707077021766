import { useQuery } from '@apollo/client';
import { getRoom } from '../../utils/variables';
import { gql } from '../../generated';

export const useCountOfContentAwatingEnhancement = () => {
    const room = getRoom();
    const ret = useQuery(ENHANCEMENTS_COUNT, {
        variables: {
            projectId: room?.project_id
        }
    });

    return {
        ...ret,
        awaitingEnhancementCount: ret.data ? ret.data?.getCountOfContentAwatingEnhancement : 0
    };
};

export const ENHANCEMENTS_COUNT = gql(`
    query getCountOfContentAwatingEnhancement($projectId: String!) {
        getCountOfContentAwatingEnhancement(projectId: $projectId)
    }
`);

import React, { useState } from 'react';
import { Table } from 'reactstrap';
import { createPortal } from 'react-dom';
import { SubCluster } from '../types';
import { Pagination } from '../../../../components/Molecules';
import { EmptyState } from '../../../../components/EmptyState';
import { NoSearchResults } from '../../../../assets/svg/noSearchResults';
import { SelectAllCheckBox } from '../../../../components/Form/CheckBox';
import { getRoom } from '../../../../utils/variables';
import { useUser } from '../../../../context/user/UserContext';
import { SubClusterListItem } from './SubClusterListItem';
import { SubClustersTableActionsBar } from './SubClustersTableActionsBar';
import { useRemoveRestoreCluster } from '../../../../services/Clusters/removeRestore';

export type SelectedSubClusters = {
    id: string
    isWatching: boolean
    title?: string
    status?: string
}

type SubClustersTableProps = {
    subClusters: SubCluster[]
    offset: number
    total: number
    onOffsetChange: (e: number) => void
    itemsPerPage: number
    isTabContent?: boolean
    keywordsToHighlight?: string[],
    isCompareModal?: boolean
    isFromRemoveContent?: boolean
}

export const SubClustersTable = ({
    subClusters,
    total,
    offset,
    onOffsetChange,
    itemsPerPage,
    isTabContent,
    keywordsToHighlight = [],
    isFromRemoveContent = false,
    isCompareModal = false
}: SubClustersTableProps) => {
    const room = getRoom();
    const { notAllowed } = useUser();
    const [selectedSubClusters, setSelectedSubClustersFn] = useState([] as SelectedSubClusters[]);
    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;
    const noActionsAllowed = notAllowed.includes('editData') || !contentActionsEnabled;
    const handleClearSelection = () => setSelectedSubClusters([]);
    const { handlePermanantRemoveSubClusters,
        handleRestoreClusters, handleSendtoRemoveContent, loading: restoreOrRemoveLoading } = useRemoveRestoreCluster();
    const handleRecover = (id: string) => {
        handleRestoreClusters([id], 'subcluster');
    };
    const handleRemove = (id: string, status: string) => {
        if (status === 'deleted') {
            handlePermanantRemoveSubClusters([id]);
        } else {
            handleSendtoRemoveContent([id], 'subcluster');
        }
    };
    const setSelectedSubClusters = (options: SelectedSubClusters[]) => {
        setSelectedSubClustersFn(
            options.map(({ id, title, isWatching }) => {
                const subCluster = subClusters.find(a => a.id === id);
                return {
                    isWatching,
                    id,
                    title: title || subCluster?.subClusterTitle || ''
                };
            })
        );
    };
    if (subClusters?.length < 1) {
        return (
            <EmptyState title="No data found."
                summary="Please change the search string and try again."
                svgIcon={<NoSearchResults />}
            />
        );
    }

    const actionBarTarget = document.getElementById('selected-cluster-view');

    const renderActionsBar = () => (
        <SubClustersTableActionsBar selectedSubClusters={selectedSubClusters}
            clearSelection={handleClearSelection}
            isFromRemoveContent={isFromRemoveContent}
        />
    );

    const renderActions = () => {
        if (actionBarTarget) {
            return createPortal(renderActionsBar(), actionBarTarget);
        }
        return renderActionsBar();
    };

    return (
        <>
            <div className={`bg-white overflow-x-auto ${isTabContent ? 'mb-3' : ''}`}>
                <Table className={`mb-${selectedSubClusters.length ? 3 : 0} border`} hover>
                    <thead>
                        <tr>
                            {!noActionsAllowed && !isCompareModal && (
                                <td className="pr-0 pl-2 select-all" data-testid="select-all-content">
                                    <SelectAllCheckBox id="subClusters"
                                        displayLabel={false}
                                        options={subClusters.map(({ id, isWatching }) => ({ id, isWatching }))}
                                        values={selectedSubClusters}
                                        allCheck={options => setSelectedSubClusters(options as SelectedSubClusters[])}
                                        page="content-table"
                                    />
                                </td>
                            )}
                            <td className="px-2 table-title">Narratives</td>
                            {isFromRemoveContent && selectedSubClusters.length === 0 && (
                                <>
                                    <td className="px-3 table-title">Removed by</td>
                                    <td className="px-3 text-right table-title">Actions</td>
                                </>

                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {subClusters.map((subCluster) => (
                            <SubClusterListItem key={subCluster.id}
                                subCluster={subCluster}
                                selectedSubClusters={selectedSubClusters}
                                onSetSelectedSubClusters={setSelectedSubClusters}
                                noActionsAllowed={noActionsAllowed}
                                keywordsToHighlight={keywordsToHighlight as string[]}
                                isTabContent={!!isTabContent}
                                isCompareModal={isCompareModal}
                                isFromRemoveContent={isFromRemoveContent}
                                restoreOrRemoveLoading={restoreOrRemoveLoading}
                                handleRecover={handleRecover}
                                handleRemove={handleRemove}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
            {selectedSubClusters.length ? (
                renderActions()
            ) : (
                <Pagination className="p-2 border"
                    total={total}
                    itemsPerPage={itemsPerPage}
                    offset={offset}
                    setOffset={onOffsetChange}
                />
            )}
        </>
    );
};

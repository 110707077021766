import React from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { VolumeOverTimeTypePlot } from './VolumeOverTimePlot';
import { calculateAverageSentiment } from '../../../../../utils/calculateAverageSentiment';
import { ClusterOrSubCluster, SubCluster } from '../../types';
import { addHighlights, useQueryKeywords } from '../../../../../services/Content/getQueryExtracts';
import { getHighlightedContent } from '../../../../../utils/getHighlightedContent';
import { Engagement } from './ClusterStats/Engagement';
import { Content } from './ClusterStats/Content';
import { Duplicates } from './ClusterStats/Duplicates';
import { SentimentCard } from './ClusterStats/SentimentCard';
import { OriginalPoster } from './ClusterStats/OriginalPoster';
import { SubClusters } from './ClusterStats/SubClusters';
import { NarrativeTheme } from './ClusterStats/NarrativeTheme';
import { getParam, setParam, unsetParam } from '../../../../../utils/urlParams';
import { saveSelectedCluster } from '../../../store';
import { GET_CLUSTERS } from '../../../../../graphql';
import { getRoom } from '../../../../../utils/variables';

type SummaryCommonProps = {
    setTab: (a: string) => void;
    parentClusterDetails: { id: string, title: string } | null
}

type SummaryProps<T extends boolean> = SummaryCommonProps & ClusterOrSubCluster<T>

export const Summary = <T extends boolean>({ cluster, isParent, setTab, parentClusterDetails }: SummaryProps<T>) => {
    const dispatch = useDispatch();
    const room = getRoom();
    const keywordsToHighlight = useQueryKeywords('clusterFilters');
    const { highlightedText: description } = addHighlights({
        text: isParent ? cluster.description : cluster.subClusterSummary,
        keywordsToHighlight
    });
    const { data: clusterData } = useQuery(GET_CLUSTERS, {
        variables: {
            limit: 1,
            projectId: room.project_id,
            id: !isParent && cluster?.topClustersMappedId
        },
        skip: !(!isParent && cluster?.topClustersMappedId)
    });
    const parentCluster = clusterData?.getClusters?.clusters ? clusterData?.getClusters?.clusters[0] : null;
    const parent = parentClusterDetails || parentCluster;
    const contentIds = isParent ? cluster?.subClusters.map((subCluster: SubCluster) => subCluster.contentIds).flat() : [];
    const handleClusterThemeCardClick = ({ id }: { id: string }) => {
        dispatch(saveSelectedCluster({
            selectedCluster: id,
            isParentCluster: true
        }));
        if (getParam('narrative')) {
            setParam('prev_narrative', getParam('narrative'));
            unsetParam('narrative');
            setParam('narrative-theme', id);
        }
    };

    return (
        <div className="bg-white p-2 selected-cluster-height scrollbar-small overflow-y-auto overflow-x-hidden">
            <p className="highlight-em w-75">{getHighlightedContent(description)}</p>
            <div className="cluster-stats-align mb-3">
                <Engagement count={cluster.engagement} disabled />
                {isParent && (
                    <SubClusters count={cluster.subClustersMappedId.length} setTab={setTab} />
                )}
                <Content count={cluster.volume} setTab={setTab} />
                <Duplicates count={cluster.duplicatedDocsCount} setTab={setTab} />
                <SentimentCard sentiments={cluster.sentimentJson}
                    averageSentiment={calculateAverageSentiment(cluster.sentimentJson)}
                    id={`sentiment_${cluster.id}`}
                />
                {!isParent && (

                    <OriginalPoster opCtype={cluster.originatorPostCtype}
                        opName={cluster.originatorPostName}
                        setTab={setTab}
                    />
                )}
                {parent && (
                    <Link to={`/situation-rooms/${room.id}/ai-narratives?narrative-theme=${parent.id}&tab=summary`}>
                        <NarrativeTheme title={parent.title}
                            subClusterId={cluster.id}
                            clusterId={parent.id}
                            onClick={handleClusterThemeCardClick}
                        />
                    </Link>
                )}
            </div>
            <hr />
            <p className="font-weight-bold mb-2">Volume over time</p>
            <VolumeOverTimeTypePlot content={cluster.volumeOverTime} contentIds={contentIds} />
        </div>
    );
};

import { isEqual } from 'lodash';
import React, { useState, useMemo, useContext, createContext } from 'react';
import { standardTemplate } from '../../pages/Room/Overview/Templates/Standard';
import { removeTypename } from '../../utils/removeAllTypename';

type OverviewContextType = {
    edit: boolean
    setEdit: React.Dispatch<React.SetStateAction<boolean>>
    layout: any
    setLayout: React.Dispatch<React.SetStateAction<any>>
    hasChanges: boolean
    handleSave: () => void
    handleCancel: () => void
    canEdit: boolean
    awaitingEnhancementCount: number
};

const OverviewContext = createContext<OverviewContextType | undefined>(undefined);

export const useOverview = () => {
    const context = useContext(OverviewContext);
    if (context === undefined) {
        throw new Error('useOverview must be used within a OverviewContextProvider');
    }
    return context;
};

type OverviewContextProviderProps = {
    children: React.ReactNode
    defaultLayout?: any
    canEdit: boolean
    awaitingEnhancementCount: number
}

export const OverviewContextProvider = ({ children, defaultLayout, canEdit, awaitingEnhancementCount }: OverviewContextProviderProps) => {
    const [edit, setEdit] = useState(false);
    const [layoutBackup, setLayoutBackup] = useState(removeTypename(defaultLayout || standardTemplate));
    const [layout, setLayout] = useState(removeTypename(defaultLayout || standardTemplate));
    const context = useMemo(() => ({
        edit,
        setEdit,
        layout,
        setLayout,
        hasChanges: !isEqual(layoutBackup, layout),
        awaitingEnhancementCount,
        handleSave: () => {
            setLayoutBackup(layout);
            setEdit(false);
        },
        handleCancel: () => {
            setLayout(layoutBackup);
            setEdit(false);
        },
        canEdit
    }), [edit, layout, layoutBackup, canEdit, awaitingEnhancementCount]);

    return (
        <OverviewContext.Provider value={context}>
            { children }
        </OverviewContext.Provider>
    );
};

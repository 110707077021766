import React, { useState } from 'react';
import { AlertCircle, X } from 'react-feather';
import { Alert, Button } from 'reactstrap';

type WarningAlertProps = {
    children: React.ReactElement | string
    className?: string
}

export const WarningAlert = ({ children, className }: WarningAlertProps) => {
    const [isOpen, setIsOpen] = useState(true);
    if (!isOpen) return null;
    return (
        <Alert color="warning" className={`d-flex align-items-center ${className || ''}`}>
            <AlertCircle className="mr-2 text-warning" />
            {children}
            <Button color="link" className="mw-0 p-0 ml-auto" onClick={() => setIsOpen(false)}>
                <X />
            </Button>
        </Alert>
    );
};

import React, { useState } from 'react';
import { Button, Col, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { calculateAverageSentiment } from '../../../../utils/calculateAverageSentiment';
import { SubCluster } from '../types';
import { ActionDropdown } from '../Utils/ActionDropdown';
import { getRoom } from '../../../../utils/variables';
import { addHighlights } from '../../../../services/Content/getQueryExtracts';
import { getHighlightedContent } from '../../../../utils/getHighlightedContent';
import { truncateString } from '../../../../utils/text';
import { SelectedSubClusters } from './SubClustersTable';
import { Engagement } from '../SelectedClusterView/Summary/ClusterStats/Engagement';
import { Content } from '../SelectedClusterView/Summary/ClusterStats/Content';
import { Duplicates } from '../SelectedClusterView/Summary/ClusterStats/Duplicates';
import { OriginalPoster } from '../SelectedClusterView/Summary/ClusterStats/OriginalPoster';
import { SentimentCard } from '../SelectedClusterView/Summary/ClusterStats/SentimentCard';
import { NarrativeTheme } from '../SelectedClusterView/Summary/ClusterStats/NarrativeTheme';
import { GET_CLUSTERS } from '../../../../graphql';
import { saveSelectedCluster } from '../../store';
import { setParam, unsetParam } from '../../../../utils/urlParams';
import { useTranslateMultipleText } from '../../../../services/google/translate';
import { TranslateButton } from '../../../../components/Atoms/TranslateButton';
import { RestoreOrRemoveNarrativesButton } from './RestoreOrRemoveNarrativesButton';
import { CompareButtons, CompareButtonType } from '../Utils/CompareButtons';
import { ExportNarratives } from './ExportNarratives';
import { WatchlistButton } from '../Utils/WatchlistButton';

type SubClusterListItemProps = {
    subCluster: SubCluster
    selectedSubClusters?: SelectedSubClusters[]
    onSetSelectedSubClusters?: (values: SelectedSubClusters[]) => void
    keywordsToHighlight?: string[]
    noActionsAllowed?: boolean
    isTabContent?: boolean
    isCompareModal?: boolean
    isFromRemoveContent?: boolean,
    restoreOrRemoveLoading?: boolean,
    handleRecover: (id: string) => void
    handleRemove: (id: string, status: string) => void
}

const textLengthLimit = 300;
const expandedTextLimit = 999999;

export const SubClusterListItem = ({
    subCluster,
    selectedSubClusters = [],
    onSetSelectedSubClusters,
    keywordsToHighlight = [],
    noActionsAllowed,
    isTabContent,
    isCompareModal = false,
    isFromRemoveContent,
    restoreOrRemoveLoading,
    handleRecover,
    handleRemove
}: SubClusterListItemProps) => {
    const dispatch = useDispatch();
    const [textLimit, setTextLimit] = useState(textLengthLimit);
    const room = getRoom();
    const { data: clusterData, loading } = useQuery(GET_CLUSTERS, {
        variables: {
            limit: 1,
            projectId: room.project_id,
            id: subCluster.topClustersMappedId
        },
        skip: !subCluster.topClustersMappedId
    });
    const parentCluster = clusterData?.getClusters?.clusters ? clusterData?.getClusters?.clusters[0] : null;
    const checked = selectedSubClusters?.map(x => x.id).indexOf(subCluster.id) === -1;
    const { highlightedText } = addHighlights({
        text: subCluster.subClusterTitle,
        keywordsToHighlight
    });
    const { highlightedText: highlightedSummary } = addHighlights({
        text: subCluster.subClusterSummary,
        keywordsToHighlight
    });
    const { getTranslation, data: translated, loading: translateLoading } = useTranslateMultipleText({
        text: highlightedText,
        extracts: [highlightedText, highlightedSummary]
    });
    const isNoneEnglish = /[^a-zA-Z0-9\s.,!?'";:()-]/.test(subCluster.subClusterTitle);
    const title = translated[0].translations[0];
    const summary = translated[1].translations[0];
    const handleCheckboxChange = () => {
        if (!checked) {
            return onSetSelectedSubClusters?.(
                selectedSubClusters?.filter(item => item.id !== subCluster.id)
            );
        }

        return onSetSelectedSubClusters?.([
            ...selectedSubClusters,
            { id: subCluster.id, isWatching: subCluster.isWatching }
        ]);
    };
    const handleTextLimitChange = () => {
        setTextLimit(textLimit === expandedTextLimit ? textLengthLimit : expandedTextLimit);
    };

    const handleClusterThemeCardClick = ({ id }: { id: string }) => {
        unsetParam('tab');
        unsetParam('hasDuplicates');
        dispatch(saveSelectedCluster({
            isParentCluster: false,
            selectedCluster: id
        }));
        setParam('narrative-theme', id);
    };

    const selectCluster = () => {
        dispatch(saveSelectedCluster({
            selectedCluster: subCluster.id,
            isParentCluster: false
        }));
    };
    const clusterLink = `/situation-rooms/${room.id}/ai-narratives/narrative/${subCluster.id}`;
    const showCheckbox = (!noActionsAllowed && !isCompareModal) || isFromRemoveContent;
    const addedDate = moment(subCluster.addedDate).utc().format('L | LTS UTC');
    return (
        <tr key={subCluster.id}>
            {showCheckbox && (
                <td className="pr-0 pl-2 align-top">
                    <CustomInput type="checkbox"
                        checked={!checked}
                        id={`contentTableViewItem-${subCluster.id}`}
                        data-testid="list-checkbox-input"
                        onChange={handleCheckboxChange}
                    />
                </td>
            )}
            <td className="p-2">
                <div id={`sub-cluster-view-${subCluster.id}`}>
                    <div className="d-flex align-items-start justify-content-between pb-2">
                        {isFromRemoveContent ? (
                            <p className="m-0 highlight-em mw-750 font-weight-bold">{getHighlightedContent(title)}
                            </p>
                        )
                            : (
                                <Link to={`${clusterLink}?tab=summary`} className="btn-link">
                                    <p className="m-0 highlight-em mw-750 mr-2 text-primary"
                                        onClick={selectCluster}
                                    >{getHighlightedContent(title)}
                                    </p>
                                </Link>
                            )}
                        {(!noActionsAllowed && !selectedSubClusters?.length && !isFromRemoveContent) && (
                            <div className="d-flex justify-content-end align-items-center content-top-bar">
                                {isNoneEnglish && (
                                    <TranslateButton className="mr-3"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            getTranslation();
                                        }}
                                        loading={translateLoading}
                                    />
                                )}
                                <CompareButtons clusterIds={[subCluster.id]}
                                    clusterTitles={[subCluster.subClusterTitle]}
                                    type={CompareButtonType.AddRemoveButton}
                                    container={`sub-cluster-view-${subCluster.id}`}
                                    id={`sub-cluster-view-${subCluster.id}_comparison`}
                                    isCompareModal={isCompareModal}
                                />
                                <WatchlistButton id={subCluster.id}
                                    isWatching={subCluster.isWatching}
                                    type="subcluster"
                                    containerId={`sub-cluster-view-${subCluster.id}`}
                                />
                                <ExportNarratives
                                    subCluster={subCluster}
                                    subClusterId={subCluster.id}
                                />
                                <ActionDropdown isSubCluster
                                    id={subCluster.id}
                                    defaultTitle={subCluster.subClusterTitle}
                                    defaultSummary={subCluster.subClusterSummary}
                                    containerId={`sub-cluster-view-${subCluster.id}`}
                                />
                            </div>
                        )}
                    </div>
                    <Col lg={8} className="mw-750 px-0 pb-2">
                        <p className="highlight-em dont-break-out d-inline">
                            {getHighlightedContent(truncateString(summary, textLimit))}&nbsp;
                        </p>
                        {(summary.length > textLimit) && (
                            <Button color="link" className="p-0 mw-0" onClick={handleTextLimitChange}>Read more</Button>
                        )}
                        {(textLimit === expandedTextLimit) && (
                            <Button color="link" className="p-0 mw-0" onClick={handleTextLimitChange}>Read less</Button>
                        )}
                    </Col>
                    <div className="cluster-stats-align m-0">
                        <Engagement count={subCluster.engagement} disabled />
                        {isFromRemoveContent
                            ? (
                                <>
                                    <Content count={subCluster.volume}
                                        subClusterId={subCluster.id}
                                        disabled
                                    />
                                    <Duplicates count={subCluster.duplicatedDocsCount}
                                        disabled
                                    />
                                </>
                            )
                            : (
                                <>
                                    <Link to={`${clusterLink}?tab=content`}>
                                        <Content count={subCluster.volume}
                                            subClusterId={subCluster.id}
                                            onClick={selectCluster}
                                        />
                                    </Link>
                                    <Link to={`${clusterLink}?tab=content&hasDuplicates=true`}>
                                        <Duplicates count={subCluster.duplicatedDocsCount} onClick={selectCluster} />
                                    </Link>
                                </>
                            )}

                        <SentimentCard id={`sentiment_${subCluster.id}`}
                            sentiments={subCluster.sentimentJson}
                            averageSentiment={calculateAverageSentiment(subCluster.sentimentJson)}
                        />
                        <OriginalPoster opCtype={subCluster.originatorPostCtype}
                            opName={subCluster.originatorPostName}
                            opId={subCluster.originatorPostId}
                        />
                        {!loading && !isTabContent && parentCluster && (
                            isFromRemoveContent
                                ? (
                                    <NarrativeTheme title={parentCluster.title}
                                        subClusterId={subCluster.id}
                                        clusterId={parentCluster.id}
                                        disabled
                                    />
                                )
                                : (
                                    <Link to={`/situation-rooms/${room.id}/ai-narratives/narrative-theme/${parentCluster.id}`}>
                                        <NarrativeTheme title={parentCluster.title}
                                            subClusterId={subCluster.id}
                                            clusterId={parentCluster.id}
                                            onClick={handleClusterThemeCardClick}
                                        />
                                    </Link>
                                )
                        )}
                    </div>
                </div>
            </td>
            {isFromRemoveContent && selectedSubClusters.length === 0 && (
                <>
                    <td className="px-3 align-top">
                        <p>{subCluster.removedBy}</p>
                        <p>{addedDate}</p>
                    </td>
                    <td className="px-3 align-top">
                        <RestoreOrRemoveNarrativesButton handleRecover={() => handleRecover(subCluster.id)}
                            handleRemove={() => handleRemove(subCluster.id, subCluster.status)}
                            restoreOrRemoveLoading={restoreOrRemoveLoading}
                        />
                    </td>
                </>
            )}
        </tr>
    );
};

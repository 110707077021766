import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { SearchInput } from '../Search/SearchInput';
import { SearchSortDropdown } from '../Search/SearchSortDropdown';
import { FiltersLayout, handleReturnFilters } from './FiltersLayout';
import {
    resetSelectedCluster,
    saveClusterFilters,
    saveFilters,
    saveSelectedFilters,
    saveWatchlistFilters
} from '../../pages/Room/store';
import { RootState } from '../../store';
import { SelectedFilters } from './components/SelectedFilters/index';
import { getRoom } from '../../utils/variables';
import { OpenButton } from './components/OpenButton';
import { OpenButton as BooleanOpenButton } from '../Search/OpenButton';
import { setParam, unsetParam } from '../../utils/urlParams';
import { BooleanSearch } from './BooleanSearch';

type SortOptionsType = {
    datetime: string,
    engagement: string,
    relevance: string
}

const sortOptions = {
    datetime: 'Most recent',
    engagement: 'Engagement',
    relevance: 'Relevance'
};

const urlRegex = /(https?:\/\/[^\s]+)/g;

type SearchBarNewProps = {
    sortOptionKey?: keyof SortOptionsType,
    sortApplied?: (e: number) => void,
    score?: boolean,
    sort?: boolean
    contentIds?: string[]
    defaultFilters?: any,
    watchlist?: boolean,
    overview?: boolean,
    clusters?: boolean,
    listViewRef?: any,
    showBooleanSearch?: boolean,
    isListViewEnabled?: boolean,
    children?: React.ReactNode
}

export type SearchBarNewRef = {
    closeFilters: () => void
}

export const SearchBarNew = forwardRef(({
    sortOptionKey,
    sortApplied,
    score,
    sort,
    contentIds,
    defaultFilters,
    watchlist = false,
    overview = false,
    clusters = false,
    showBooleanSearch = false,
    listViewRef,
    isListViewEnabled,
    children
}: SearchBarNewProps, ref) => {
    const dispatch = useDispatch();
    const room = getRoom();
    const filters = useSelector((state: any) => handleReturnFilters(state, watchlist, clusters));
    const allFilters = useSelector((state: RootState) => state.selectedFilters.filters);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [isBooleanOpen, setIsBooleanOpen] = useState(false);
    const [searchVal, setVal] = useState(filters.query ? filters.query : '');
    const [sortOption, setSortOption] = useState(sortOptions[sortOptionKey || 'datetime']);
    const hasFilters = filters.contentType?.length > 0
      || filters.sourcesWithDomains?.length > 0
      || filters.languages?.length > 0 || filters.origin?.length > 0
      || (filters.date?.startDate ? (filters.date?.startDate !== 0 && filters.date.endDate !== 0) : false)
      || filters.keywords?.length > 0 || ((overview || clusters) ? false : filters.threatTypes?.length > 0)
      || ((overview || clusters) ? false : filters.threatResolvers?.length > 0)
      || filters.from?.length > 0 || filters.sentiment?.length > 0 || (clusters ? false : filters.labels?.length > 0)
      || (clusters ? false : (filters.entities.length > 0 || filters.mentions.length > 0 || filters.hashtags.length > 0));
    const hasBooleanFilter = filters.booleanQuery?.length > 0;
    const [filterName, setFilterName] = useState(hasFilters ? 'Custom selected' : 'Filters');

    useImperativeHandle(ref, () => ({
        closeFilters: () => {
            setIsFiltersOpen(false);
            setIsBooleanOpen(false);
        }
    }));

    useEffect(() => {
        setVal(filters.query);
    }, [filters]);
    const handleSearch = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (watchlist) {
            return dispatch(saveWatchlistFilters({ query: searchVal.replace(
                urlRegex,
                (url: string) => `"${url}"`
            ).replaceAll('""', '"') }));
        }
        if (clusters) {
            dispatch(resetSelectedCluster());
            setParam('query', searchVal);
            if (isListViewEnabled && listViewRef.current) {
                listViewRef.current.handleSearch();
            }
            if (!searchVal) {
                unsetParam('query');
            }
            return dispatch(saveClusterFilters({ query: searchVal.replace(urlRegex, (url: string) => `"${url}"`).replaceAll('""', '"') }));
        }
        return dispatch(saveFilters({ query: searchVal.replace(urlRegex, (url: string) => `"${url}"`).replaceAll('""', '"') }));
    };
    const handleSort = (num: number, e: React.MouseEvent<any>) => {
        if (sortApplied) { sortApplied(num); }
        setSortOption(e.currentTarget.name);
    };
    const dateCheck = _.isEqual({
        startDate: 0,
        endDate: 0,
    }, allFilters.dateRange);
    let noneSelected = true;

    const finalFilters = (overview || clusters) ? { ...allFilters,
        threatTypes: [],
        ...(clusters ? { mentions: [], hashtags: [], entities: [] } : {}) } : allFilters;
    Object.entries(finalFilters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            if (value.length > 0) { noneSelected = false; }
        }
    });
    const isSelected = !noneSelected || (!dateCheck) || filters.booleanQuery;
    useEffect(() => {
        dispatch(saveSelectedFilters({
            keywords: filters.keywords || [],
            languages: filters.languages || [],
            contentType: filters.contentType || [],
            from: filters.from || [],
            dateRange: (filters.dateRange?.startDate && (filters.dateRange?.startDate
                !== moment(moment(room.start_date).toDate()).unix()))
                ? filters.dateRange : { startDate: 0, endDate: 0 },
            sentiment: filters.sentiment || [],
            labels: clusters ? [] : (filters.labels || []),
            ids: filters.ids || (watchlist ? contentIds : []),
            threatTypes: (overview || clusters) ? [] : (filters.threatTypes || []),
            origin: filters.origin || [],
            entities: (clusters ? [] : (filters.entities || [])),
            hashtags: (clusters ? [] : (filters.hashtags || [])),
            mentions: (clusters ? [] : (filters.mentions || [])),
            booleanQuery: (clusters ? '' : (filters.booleanQuery || '')),
            queryName: ''
        }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div data-testid="search-bar-component">
            <Row className="d-flex flex-wrap">
                <Col xs={12} sm="auto" className="sm-flex-1">
                    <SearchInput placeholder="Search by keywords"
                        searchVal={searchVal}
                        setVal={setVal}
                        handleSearch={handleSearch}
                        clusters={clusters}
                        className="mr-0 search-bar-input"
                    />
                </Col>
                {showBooleanSearch && (
                    <Col xs="auto">
                        <BooleanOpenButton name="Boolean search"
                            handleClick={() => { if (isFiltersOpen) {
                                setIsBooleanOpen(!isBooleanOpen); setIsFiltersOpen(false); }
                            else {
                                setIsBooleanOpen(!isBooleanOpen);
                            } }}
                            isOpen={isBooleanOpen}
                            hasValues={hasBooleanFilter}
                        />
                    </Col>
                )}
                <Col xs="auto">
                    <OpenButton name={filterName}
                        handleClick={() => { if (isBooleanOpen) {
                            setIsFiltersOpen(!isFiltersOpen); setIsBooleanOpen(false); }
                        else {
                            setIsFiltersOpen(!isFiltersOpen);
                        } }}
                        isOpen={isFiltersOpen}
                        hasValues={hasFilters}
                        setFilterName={setFilterName}
                    />
                </Col>
                {sort && (
                    <Col xs="auto">
                        <SearchSortDropdown score={score} sortOption={sortOption} handleSort={handleSort} />
                    </Col>
                )}
                {children && children}
            </Row>
            <Collapse isOpen={isBooleanOpen} data-testid="boolean-filters">
                <div className="mt-2">
                    <BooleanSearch setIsBooleanOpen={setIsBooleanOpen} showBooleanSearch={showBooleanSearch} />
                </div>
            </Collapse>
            <Collapse isOpen={isFiltersOpen} data-testid="search-filters">
                <div className="mt-2">
                    <FiltersLayout isFiltersOpen={isFiltersOpen}
                        setIsFiltersOpen={setIsFiltersOpen}
                        setFilterName={setFilterName}
                        filterName={filterName}
                        hasFilters={hasFilters}
                        contentIds={contentIds}
                        defaultFilters={defaultFilters}
                        watchlist={watchlist}
                        overview={overview}
                        clusters={clusters}
                    />
                </div>
            </Collapse>
            <Collapse isOpen={(filterName !== 'Filters' || !!filters.booleanQuery) && !isFiltersOpen && isSelected
                && !isBooleanOpen}
            >
                <div className="mt-2">
                    <SelectedFilters setFilterName={setFilterName}
                        start_date={room?.start_date}
                        display
                        contentIds={contentIds}
                        watchlist={watchlist}
                        clusters={clusters}
                    />
                </div>
            </Collapse>
        </div>
    );
});

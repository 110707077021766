import { set, cloneDeep, isEqual } from 'lodash';
import React, { useState, useMemo, useContext, createContext } from 'react';
import { useParams } from 'react-router-dom';

const InstanceContext = createContext({});

export const useInstance = () => useContext(InstanceContext);

export const InstanceContextProvider = ({ children, defaultInstance }) => {
    const [instanceBackup, setInstanceBackup] = useState(defaultInstance || instanceTemplate);
    const [instance, setInstance] = useState(defaultInstance || instanceTemplate);
    const [error, setError] = useState(false);
    const params = useParams();
    const context = useMemo(() => ({
        instance,
        setInstance,
        handleChange: (val, field) => {
            if (error?.field === field) {
                setError(false);
            }
            setInstance(set(cloneDeep(instance), field, val));
        },
        isNew: params.id === 'create',
        changesMade: !isEqual(instance, instanceBackup),
        resetInstance: () => setInstance(instanceBackup),
        saveInstance: () => setInstanceBackup(instance),
        error,
        setError,
        updateId: (id) => {
            setInstanceBackup({ ...instanceBackup, id });
            setInstance({ ...instance, id });
        }
    }), [instance, params, instanceBackup, error]);

    return (
        <InstanceContext.Provider value={context}>
            { children }
        </InstanceContext.Provider>
    );
};

const instanceTemplate = {
    clientName: '',
    account: {
        accountManager: '',
        contactName: '',
        contractLink: '',
        email: '',
        location: '',
        notes: '',
        phoneNumber: '',
        role: ''
    },
    plan: {
        core: {
            logicallyFactChecks: true,
            narratives: true,
            overview: true,
            threats: true,
            aiClustering: false,
            networks: true
        },
        integrations: {
            investigations: false,
            watchlist: true,
            reports: true,
            crowdtangleCSV: false,
            identifiedActorEnrichments: false
        },
        others: {
            contentActions: true,
            aiNarrativeCoordinates: true,
            dateInLocalTimeZone: false,
            betaFilters: false,
            labels: false,
            newContentTableView: false,
            aiNarrativeComparisons: true,
            newEtl: true
        },
        isTrial: false,
        restrictedTeamRole: false,
        situationroomLimit: 10,
        disabledThreatTypes: ['domestic_extremism'],
        userLimit: 10,
        tokens: 10,
        validFrom: new Date().getTime(),
        validUntil: new Date().getTime() + (1000 * 60 * 60 * 24 * 30) // 30 days
    }
};

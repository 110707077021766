import React from 'react';
import { Button } from 'reactstrap';
import { SelectedClusters } from './ClustersTable';
import { useModal } from '../../../../context/modal/ModalComponent';
import { RemoveClustersConfirmation } from '../Utils/ActionDropdown/RemoveClustersConfirmation';

type RemoveClusterButtonProps = {
    selectedClusters: SelectedClusters[]
    onCompleted: () => void
    className?: any
}

export const RemoveClusterButton = ({
    selectedClusters,
    onCompleted,
    className = {}
}: RemoveClusterButtonProps) => {
    const { setModal } = useModal();

    const setRemoveClustersModal = () => {
        const removeData = selectedClusters.map(({ id, status }) => ({ id, status }));
        setModal({
            component: <RemoveClustersConfirmation clusterIdAndStatus={removeData} onCompleted={onCompleted} />
        });
    };
    return (
        <Button color="danger" onClick={setRemoveClustersModal} data-testid="remove-clusters-button" className={className}>
            Remove Narrative theme{selectedClusters.length > 1 && 's'}
        </Button>
    );
};

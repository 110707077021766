import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { getRoom, getUser } from '../../utils/variables';
import { getToast } from '../../utils/getToast';
import { CHANGE_CLUSTER_OR_SUBCLUSTER_STATUS, REMOVE_CLUSTERS, REMOVE_SUB_CLUSTERS } from '../../graphql';
import { useModal } from '../../context/modal/ModalComponent';

type ClusterType = 'cluster' | 'subcluster';

enum Status {
    deleted = 'deleted',
    updated = 'updated',
    created = 'created'
}

export const useRemoveRestoreCluster = () => {
    const room = getRoom();
    const toast = getToast();
    const history = useHistory();
    // used let instead of state as state is async.
    let clusterType = '';
    const user = getUser();
    const { closeModal } = useModal();
    const [changeStatus, { loading: changeStatusLoading }] = useMutation(CHANGE_CLUSTER_OR_SUBCLUSTER_STATUS, {
        refetchQueries: 'active',
        onCompleted: () => {
            toast.success(`Narrative ${clusterType === 'cluster' ? 'themes' : ''}removed successfully`);
            closeModal();
        },
        onError: () => {
            toast.error(`Failed to remove narratives ${clusterType === 'cluster' ? 'themes' : ''}`);
            closeModal();
        },
    });
    const [removeClusters, { loading: premanentRemoveLoading }] = useMutation(REMOVE_CLUSTERS, {
        refetchQueries: 'active',
        onCompleted: () => {
            toast.success(`Narrative ${clusterType === 'cluster' ? 'themes' : ''} removed successfully`);
            closeModal();
        },
        onError: () => {
            toast.error(`Failed to remove narrative ${clusterType === 'cluster' ? 'themes' : ''}`);
            closeModal();
        }
    });
    const [removeSubClusters, { loading: premanentRemoveSubClusterLoading }] = useMutation(REMOVE_SUB_CLUSTERS, {
        refetchQueries: 'active',
        onCompleted: () => {
            toast.success(`Narrative ${clusterType === 'cluster' ? 'themes' : ''} removed successfully`);
            closeModal();
        },
        onError: () => {
            toast.error(`Failed to remove narrative ${clusterType === 'cluster' ? 'themes' : ''}`);
            closeModal();
        }
    });
    const handlePermanantRemoveClusters = (clusterIds: string[]) => history.location?.pathname?.includes('settings') && removeClusters({
        variables: {
            projectId: room.project_id,
            clusterIds
        }
    });
    const handlePermanantRemoveSubClusters = (clusterIds: string[]) => history.location?.pathname?.includes('settings')
    && removeSubClusters({
        variables: {
            projectId: room.project_id,
            subClusterIds: clusterIds
        }
    });
    const handleRestoreClusters = (clusterIds: string[], type: ClusterType) => {
        clusterType = type;
        changeStatus({
            variables: {
                projectId: room.project_id,
                clusterIds: type === 'cluster' ? clusterIds : [],
                subClusterIds: type === 'subcluster' ? clusterIds : [],
                removedBy: user.displayName,
                status: Status.updated
            },
            update: (cache) => {
                for (const id of clusterIds) {
                    cache.modify({
                        id: cache.identify({ __typename: type === 'cluster' ? 'Cluster' : 'SubCluster', id }),
                        fields: {
                            status: () => Status.updated
                        }
                    });
                }
            },
        }); };
    const handleSendtoRemoveContent = (clusterIds: string[], type: ClusterType) => {
        clusterType = type;
        changeStatus({
            variables: {
                projectId: room.project_id,
                clusterIds: type === 'cluster' ? clusterIds : [],
                subClusterIds: type === 'subcluster' ? clusterIds : [],
                removedBy: user.displayName,
                status: Status.deleted
            },
            update: (cache) => {
                for (const id of clusterIds) {
                    cache.modify({
                        id: cache.identify({ __typename: type === 'cluster' ? 'Cluster' : 'SubCluster', id }),
                        fields: {
                            status: () => Status.deleted
                        }
                    });
                }
            },
        }); };
    return {
        handleRestoreClusters,
        handleSendtoRemoveContent,
        handlePermanantRemoveClusters,
        handlePermanantRemoveSubClusters,
        loading: changeStatusLoading || premanentRemoveLoading || premanentRemoveSubClusterLoading
    };
};

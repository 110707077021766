import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useContent } from '../../../../../services/Content/getContent';
import { Loading } from '../../../../../components/Loading';
import { ReadMore } from '../../../../../components/Readmore';
import { getPostedByName } from '../../../../../utils/getPostedByName';
import { formatDate } from '../../../../../utils/getTimeFrom';
import { largeNumber } from '../../../../../utils/NumberFormat';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getContentLink } from '../../../../../utils/getContentLink';
import { TranslateButton } from '../../../../../components/Atoms/TranslateButton';
import { TranslatedFromLanguage } from '../../../../../utils/TranslatedFromLanguage';
import { useTranslateMultipleText } from '../../../../../services/google/translate';

type ContentDetailsProps = {
    id: string
}

export const ContentDetails = ({ id }: ContentDetailsProps) => {
    const { closeModal } = useModal();
    const [showLanguages, setShowLangauges] = useState(false);
    const { data, loading } = useContent({
        filters: {
            ids: id
        },
        limit: 1,
        name: 'getContentDetails',
        sort: 'date',
        fragments: ['Metadata', 'Document', 'Engagement', 'Annotations']
    });
    const content = data[0];
    const { getTranslation, data: translated, loading: translateLoading } = useTranslateMultipleText({
        extracts: [content?.text, content?.body]
    });
    const translatedText = translated[0].translations[0];
    const translatedBody = translated[1].translations[0];

    if (loading) return <Loading relative height={200} />;

    return (
        <div className="dont-break-out">
            {showLanguages && !translateLoading && (
                <TranslatedFromLanguage languageVerbose={content?.langVerbose} />
            )}
            {content.text.length > 400
                ? <ReadMore text={translatedText} limit={400} className="font-weight-bold" />
                : <p className="font-weight-bold">{translatedText}</p>}
            {content.text !== content.body && (
                translatedBody > 400 ? <ReadMore text={translatedBody} limit={400} /> : <p>{translatedBody}</p>
            ) }
            <TranslateButton className="w-25"
                onClick={(e) => {
                    e.preventDefault();
                    getTranslation();
                    setShowLangauges(!showLanguages);
                }}
                lang={content.langVerbose}
                detectedLangs={content.detectedLangs}
                loading={translateLoading}
                showText
            />
            <hr />
            <p>Engagement: {content.engagement === -1 ? 'N/A' : largeNumber(content.engagement)}</p>
            <p>Posted by: {getPostedByName(content.from, content.display_name)}</p>
            <p>Posted on: {formatDate(content.pubdatetime, 'DD/MM/YYYY @ HH:mm z')}</p>
            <hr />
            <Button onClick={closeModal} className="mr-2">Close</Button>
            <Link to={getContentLink({ id })} onClick={closeModal}>
                <Button color="primary">View content source</Button>
            </Link>
        </div>
    );
};

import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_CLUSTERS_FOR_CHART, GET_SUB_CLUSTERS_FOR_CHART } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';
import { ClusterForChart, ClusterForChartWithSubClusters, SubClusterForChart } from '../types';
import { Loading } from '../../../../components/Loading';
import { BubbleChart } from '.';
import { useGlobalFiltersInClusters } from '../../../../services/Clusters/GlobalFIltersInClusters';

type BubbleChartWrapperProps = {
    allClusterIds: (data: {
        narrativeTheme: { id: string, title: string, subClustersMappedId: string[] }[]
        narrative: string[][]
    }) => void;
}

export const BubbleChartWrapper = ({ allClusterIds }: BubbleChartWrapperProps) => {
    const room = getRoom();
    const filters = useGlobalFiltersInClusters({ useGlobalFilters: true });
    const { data, loading: clusterLoading } = useQuery(GET_CLUSTERS_FOR_CHART, {
        variables: {
            ...filters
        },
        fetchPolicy: 'cache-and-network',
        onCompleted: (clusterData) => {
            const narrativeTheme = clusterData?.getClusters?.clusters.map((cluster:
                { id: string; title: string, subClustersMappedId: string[] }) => ({
                id: cluster.id,
                title: cluster.title,
                subClustersMappedId: cluster.subClustersMappedId
            }));
            const narrative = clusterData?.getClusters?.clusters
                .map((cluster: { subClustersMappedId: string; }) => cluster.subClustersMappedId);
            allClusterIds({ narrativeTheme, narrative });
        },
    });
    const clustersData = (data?.getClusters?.clusters || []) as ClusterForChart[];
    const subClusterIds = clustersData.map((cluster) => cluster.subClustersMappedId)?.flat() || [];
    const { data: subClusters, loading: subClusterLoading } = useQuery(GET_SUB_CLUSTERS_FOR_CHART, {
        skip: !subClusterIds.length,
        variables: {
            projectId: room.project_id,
            ids: subClusterIds
        },
        fetchPolicy: 'cache-and-network'
    });
    const subClustersData = (subClusters?.getSubClusters || []) as SubClusterForChart[];

    const clusters = clustersData.map((cluster) => ({
        ...cluster,
        subClusters: subClustersData.filter((subCluster) => cluster.subClustersMappedId.includes(subCluster.id))
    })) as ClusterForChartWithSubClusters[];

    if ((clusterLoading || subClusterLoading) && !clusters.length) {
        return (<Loading gif relative height={600} />);
    }

    return <BubbleChart clusters={clusters} key={clustersData.map(({ id }) => id).join('-')} />;
};

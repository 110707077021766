import React, { useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import moment from 'moment';
import { CustomInput } from 'reactstrap';
import { SplineChart } from '../../../../components/Highcharts/Spline';
import { IconButton } from '../../../../components/Atoms';
import { Cluster, SubCluster } from '../types';
import { LoadingState } from '../../Overview/Visualisation/TotalMatchesAndThreatsOverTime/Loading';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';

type TimelineChartProps = {
    isCollapsed: boolean;
    setCollapsable: () => void;
    data: SubCluster[] | Cluster[];
    colors: string[];
    isParent: boolean;
    loading: boolean;
};

export const TimelineChart = ({ isCollapsed, setCollapsable, data, colors, isParent, loading }: TimelineChartProps) => {
    const [type, setType] = useState<string>('volume');
    const [selectedSeries, setSelectedSeries] = useState<{ id: string[]; colors: { [key: string]: string } }>({
        id: [],
        colors: {}
    });

    const handleSeriesToggle = (id: string) => {
        setSelectedSeries(prevSelected => {
            const isAlreadySelected = prevSelected.id.includes(id);
            return {
                id: isAlreadySelected ? prevSelected.id.filter(item => item !== id) : [...prevSelected.id, id],
                colors: prevSelected.colors
            };
        });
    };
    const seriesData = useMemo(
        () =>
            data
                ?.filter(innerClusterData => selectedSeries?.id.includes(innerClusterData?.id))
                .map(innerClusterData => {
                    const singleCluster = type === 'volume' ? innerClusterData?.volumeOverTime || []
                        : innerClusterData?.engagementOverTime || [];
                    const newClusterData = singleCluster?.map(item => ({
                        x: type === 'volume' ? moment(isParent ? item.time : item.date, 'DD-MM-YYYY').valueOf()
                            : moment(item.time, 'DD-MM-YYYY').valueOf(),
                        y: item.count
                    }))
                        .sort((a, b) => a.x - b.x); // Sort by timestamp
                    return {
                        data: newClusterData,
                        name: isParent
                            ? (innerClusterData as SubCluster)?.subClusterTitle
                            : (innerClusterData as Cluster)?.title,
                        color: selectedSeries.colors[innerClusterData.id] // Use color from selectedSeries
                    };
                }),
        [data, selectedSeries, type, isParent]
    );
    useEffect(() => {
        if (data) {
            setSelectedSeries({
                id: data.map(innerClusterData => innerClusterData.id),
                colors: data.reduce((acc, innerClusterData, index) => {
                    acc[innerClusterData.id] = colors[index];
                    return acc;
                }, {} as { [key: string]: string })
            });
        }
    }, [data, colors]);

    return (
        <div className="mb-4">
            <IconButton
                className="icon-button text-decoration-none"
                icon={isCollapsed ? <ChevronDown /> : <ChevronRight />}
                onClick={setCollapsable}
                text={<WidgetTextTooltip widgetName={WidgetName.Timeline} noMargin className="ml-2" />}
                dataTestid="timeline"
            />
            {isCollapsed && (
                <div className="bg-white p-2 mt-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex px-1">
                            <input
                                type="radio"
                                id="volume"
                                name="perf-graph"
                                value="volume"
                                checked={type === 'volume'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setType('volume');
                                    }
                                }}
                            />
                            <span className="pt-1 text-primary">Volume</span>
                            <input
                                className="ml-2"
                                type="radio"
                                id="engagement"
                                name="perf-graph"
                                value="engagement"
                                checked={type === 'engagement'}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setType('engagement');
                                    }
                                }}
                            />
                            <span className="pt-1 text-primary">Engagement</span>
                        </div>
                        <div className="d-flex align-items-center gap-2 mt-1">
                            {data?.map((item, index) => (
                                <div className="mr-1 d-flex" key={item.id}>
                                    <CustomInput
                                        id={item.id}
                                        type="checkbox"
                                        color={colors[index % colors.length].replaceAll('#', '-')}
                                        className={`checkbox-color${colors[index % colors.length].replaceAll('#', '-')}`}
                                        checked={selectedSeries.id.includes(item.id)}
                                        onChange={() => handleSeriesToggle(item.id)}
                                    />
                                    <p>
                                        {`Narrative ${index + 1}`}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="mt-3">
                        {loading ? <LoadingState items={[1]} /> : <SplineChart height={350} seriesData={seriesData} />}
                    </div>
                </div>
            )}
        </div>
    );
};

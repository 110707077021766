import React from 'react';
import { Table } from 'reactstrap';
import { SelectAllCheckBox } from '../../Form/CheckBox';
import { ContentTableViewFooter } from './ContentTableViewFooter';
import { ContentTableViewItem } from './ContentTableViewItem';
import { Content as ContentType } from '../../../services/Content/contentInterface';

type PaginationType = {
    count: number
    offset: number
    setOffset: (e: number) => void;
}

export type ContentTableContentType = ContentType;

type ContentTableViewProps = {
    contents: ContentTableContentType[]
    selectedContentItems: ContentTableContentType[]
    total: number
    pagination?: PaginationType
    allowSelectBulkActions: boolean
    noActionsAllowed: boolean
    bulkContentsSelected?: boolean
    onSelectAll: () => void
    onClearSelection: () => void
    onBulkContentsSelected?: (value: boolean) => void
    onSetSelectedContentItems: (values: ContentTableContentType[]) => void
    onRemoveContent?: (data: { id: string }[]) => void
    onMarkAsThreat?: (id: string) => void
    onMarkAsNotThreat?: (ids: string[]) => void
    onRequestInvestigation?: (content: ContentTableContentType) => void
    onBatchContentRemove?: () => void
}

export const ContentTableView = ({
    contents,
    selectedContentItems,
    pagination,
    allowSelectBulkActions,
    noActionsAllowed,
    bulkContentsSelected,
    total,
    onSelectAll,
    onClearSelection,
    onBulkContentsSelected,
    onSetSelectedContentItems,
    onRemoveContent,
    onMarkAsThreat,
    onMarkAsNotThreat,
    onRequestInvestigation,
    onBatchContentRemove
}: ContentTableViewProps) => {
    if ((pagination && !pagination.count) || (!pagination && !contents.length)) return null;

    return (
        <>
            <div className="bg-white overflow-x-auto">
                <Table className="m-0">
                    <thead>
                        <tr>
                            {!noActionsAllowed && (
                                <td className="pr-0" data-testid="select-all-content">
                                    <SelectAllCheckBox id="allThreatsContent"
                                        displayLabel={false}
                                        options={contents}
                                        values={selectedContentItems}
                                        allCheck={(options) => onSetSelectedContentItems(options as ContentTableContentType[])}
                                        page="content-table"
                                    />
                                </td>
                            )}
                            <td className={noActionsAllowed ? '' : 'pl-1'}>Select all</td>
                        </tr>
                    </thead>
                    <tbody data-testid="content-list-items">
                        {contents.map((content) => (
                            <ContentTableViewItem key={content.id}
                                content={content}
                                selectedContentItems={selectedContentItems}
                                noActionsAllowed={noActionsAllowed}
                                onSetSelectedContentItems={onSetSelectedContentItems}
                                onRemoveContent={onRemoveContent}
                                onMarkAsThreat={onMarkAsThreat}
                                onMarkAsNotThreat={onMarkAsNotThreat}
                                onRequestInvestigation={onRequestInvestigation}
                            />
                        ))}
                    </tbody>
                </Table>
            </div>
            <ContentTableViewFooter selectedContentItems={selectedContentItems}
                pagination={pagination}
                bulkContentsSelected={bulkContentsSelected}
                allowSelectBulkActions={allowSelectBulkActions}
                total={total}
                onSelectAll={onSelectAll}
                onBulkContentsSelected={onBulkContentsSelected}
                onRemoveContent={onRemoveContent}
                clearSelection={onClearSelection}
                onMarkAsNotThreat={onMarkAsNotThreat}
                onBatchContentRemove={onBatchContentRemove}
            />
        </>
    );
};

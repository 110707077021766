import React from 'react';
import { RotateCcw, Trash2 } from 'react-feather';
import { Button } from 'reactstrap';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';
import { color } from '../../../../utils/getColors';
import { useModal } from '../../../../context/modal/ModalComponent';
import { getRoom } from '../../../../utils/variables';

type RestoreOrRemoveNarrativesButtonProps = {
    handleRecover: () => void
    handleRemove: () => void
    isFromBatchMode?: boolean
    restoreOrRemoveLoading?: boolean
}

export const RestoreOrRemoveNarrativesButton = ({ handleRecover,
    handleRemove, isFromBatchMode, restoreOrRemoveLoading }: RestoreOrRemoveNarrativesButtonProps) => {
    const { setModal, closeModal } = useModal();
    const room = getRoom();
    const multiContentString = isFromBatchMode ? 'these contents' : 'this content';
    const handleRecoverModal = () => {
        setModal({
            header: 'Recover content',
            component: (
                <div>
                    <p>{`Are you sure you want to recover ${multiContentString}.
                        This will be moved back to the "${room.project_name}" situation
                        room and it will be visible to you and your team in the corresponding section.`}
                    </p>
                    <div className="d-flex">
                        <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
                        <Button color="primary" disabled={restoreOrRemoveLoading} onClick={handleRecover}>Recover</Button>
                    </div>
                </div>
            )
        });
    };
    const handleRemoveModal = () => {
        setModal({
            header: 'Permanently delete content',
            component: (
                <div>
                    <p>{`Are you sure you want to permanently delete ${multiContentString}.
                    You and your team won’t be able to see it. This process cannot be undone.`}
                    </p>
                    <div className="d-flex">
                        <Button color="secondary"
                            data-testid="deleteIconCancle"
                            className="mr-2"
                            onClick={closeModal}
                        >Cancel
                        </Button>
                        <Button color="danger" disabled={restoreOrRemoveLoading} onClick={handleRemove}>Delete</Button>
                    </div>
                </div>
            )
        });
    };
    if (isFromBatchMode) {
        return (
            <div className="d-flex justify-content-end gap-2">
                <Button onClick={handleRecoverModal} disabled={restoreOrRemoveLoading} color="primary">Recover</Button>
                <Button onClick={handleRemoveModal} disabled={restoreOrRemoveLoading} color="danger">Delete</Button>
            </div>
        );
    }
    return (
        <div className="d-flex justify-content-end gap-2">
            <TooltipWrapper id="recover" tooltipText="Recover">
                <RotateCcw color={color.blue[500]}
                    className="flex-end cursor-pointer"
                    onClick={handleRecoverModal}
                />
            </TooltipWrapper>
            <TooltipWrapper id="delete" data-testid="deleteIcon" tooltipText="Delete">
                <Trash2 color={color.red[200]}
                    data-testid="deleteIcon"
                    className="flex-end cursor-pointer"
                    onClick={handleRemoveModal}
                />
            </TooltipWrapper>
        </div>
    ); };

export const nodeColors = {
    positive: {
        combo: 'rgb(160, 255, 188)',
        node: 'rgb(19, 186, 156)',
        nodeBorder: 'rgb(19, 186, 156)'
    },
    negative: {
        combo: 'rgb(255, 169, 205)',
        node: 'rgb(251, 118, 154)',
        nodeBorder: 'rgb(248, 67, 103)'
    },
    neutral: {
        combo: 'rgb(255, 255, 168)',
        node: 'rgb(253, 235, 117)',
        nodeBorder: 'rgb(252, 205, 19)'
    },
    unknown: {
        combo: 'rgb(206, 150, 243)',
        node: 'rgb(231, 194, 255)',
        nodeBorder: 'rgb(94, 18, 145)'
    }
};

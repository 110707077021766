import React, {
    useMemo,
    createContext,
    useContext,
    useEffect,
    useState
} from 'react';
import { Modal, ModalBody } from 'reactstrap';
import uuid from 'uuid-random';
import { useDispatch, useSelector } from 'react-redux';
import * as FeatherIcon from 'react-feather';
import { useLocation } from 'react-router-dom';
import { closeModalAction, openPreviousModalAction, setModalsAction } from './store';

const ModalContext = createContext();

// Custom react hook for the modal
export const useModal = () => useContext(ModalContext);

// Modal Context Provider to wrap the application in the modal context
export const ModalContextProvider = ({ children, mocks }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [initialLocation] = useState(location.pathname);
    const { modals } = useSelector((state) => state.modals);
    let context = useMemo(() => ({
        setModal: (props) => dispatch(setModalsAction([props])),
        closeModal: () => dispatch(closeModalAction()),
        openPreviousModal: (modalNumber) => dispatch(openPreviousModalAction(modalNumber)),
        isOpen: modals.length > 0,
    }), [dispatch, modals]);

    if (mocks) {
        context = {
            setModal: mocks.setModal || context.setModal,
            closeModal: mocks.closeModal || context.closeModal,
            openPreviousModal: mocks.openPreviousModal || context.openPreviousModal,
            isOpen: mocks.isOpen || context.isOpen,
        };
    }

    useEffect(() => {
        if (location.pathname !== initialLocation) {
            dispatch(closeModalAction());
        }
    }, [location.pathname, dispatch, initialLocation]);

    return (
        <ModalContext.Provider value={context}>
            {modals.length ? (
                <>
                    {modals.map((modal, i) => {
                        if (i !== modals.length - 1 && !modal.keepOpen) return null;
                        return (
                            <ModalComponent header={modal?.header}
                                component={modal?.component}
                                modal={modal?.modal}
                                size={modal?.size}
                                onClose={modal?.onClose}
                                key={uuid()}
                                backdrop={modal?.backdrop}
                            />
                        );
                    })}
                </>
            ) : null}
            { children }
        </ModalContext.Provider>
    );
};

export const ModalComponent = ({
    modal,
    header = false,
    component,
    size = 'md',
    onClose,
    backdrop
} = {}) => {
    const { closeModal } = useModal();
    // Allow to override with the fully created custom modal
    if (modal) {
        return modal;
    }

    return (
        <Modal isOpen={!!component || !!header}
            toggle={onClose || closeModal}
            size={size}
            className={size === 'full' ? 'fullscreen-modal' : ''}
            backdrop={backdrop}
        >
            <ModalBody className="">
                {header !== false && (
                    <>
                        <div className="d-flex justify-content-between">
                            <h2 className="mt-0 mb-0">{header}</h2>
                            <FeatherIcon.X color="#050E2B"
                                size={20}
                                data-testid="close-modal"
                                className="cursor-pointer mt-11"
                                stroke="#050E2B"
                                onClick={onClose || closeModal}
                            />
                        </div>
                        <hr className="mb-2 mt-2" />
                    </>
                )}
                {component}
            </ModalBody>
        </Modal>
    );
};

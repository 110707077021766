import React, { useState } from 'react';
import { AlertTriangle, Eye, EyeOff } from 'react-feather';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { color } from '../../utils/getColors';

type PasswordInputProps = {
    password: string,
    setPassword: (e: string) => void,
    testId?: string,
    required: boolean
    setError?: (msg: string) => void
    state?: boolean
}

export const PasswordInput = ({ password, setPassword, testId, required = false, setError, state }: PasswordInputProps) => {
    const [inputType, setInputType] = useState('password');
    return (
        <InputGroup border="none" className={`m-0 ${state ? 'border-danger' : ''}`}>
            <Input name="password"
                data-testid={testId || 'passwordInput'}
                value={password}
                onChange={(e) => { setPassword(e.target.value); setError && setError(''); }}
                type={inputType === 'password' ? 'password' : 'text'}
                required={required}
            />
            {password && !state && (
                <InputGroupAddon addonType="prepend">
                    <InputGroupText className="border-0">
                        {inputType === 'password'
                            ? (
                                <EyeOff className="pr-11"
                                    color={color.blue[500]}
                                    onClick={() => setInputType('text')}
                                />
                            )
                            : <Eye className="pr-11" color={color.blue[500]} onClick={() => setInputType('password')} />}
                    </InputGroupText>
                </InputGroupAddon>
            )}
            {state && (
                <InputGroupAddon addonType="prepend">
                    <InputGroupText className="border-0">
                        <AlertTriangle color={color.red[200]} onClick={() => setInputType('password')} />
                    </InputGroupText>
                </InputGroupAddon>
            )}
        </InputGroup>
    );
};

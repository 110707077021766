import React, { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import { useSituationRoomByID } from '../../../services/SituationRooms/useSituationRoomByID';
import { useSituationRoomDraftByID } from '../../../services/SituationRooms/useSituationRoomDraftByID';
import { Tabs } from './Tabs';
import { RoomEditorContextProvider } from './RoomEditorContext';
import { Versions } from './Versions';
import { RoomDetails } from './RoomDetails/index.js';
import { Header } from './Header';
import { RoomCreation } from './RoomCreation';
import { RoomEditorFooter } from './Footer';
import { SplitView } from './SplitView';
import { RightPanel } from './RightPanel';
import { useGetInstance } from '../../../services/Instance/getInstance';
import { useUser } from '../../../context/user/UserContext';
import { DashboardDefault } from '../../../assets/svg/DashboadDefault';

type RoomEditorRouteParams = {
    params: {
        roomId: string
        draftId: string
    }
}

type RoomEditorProps = {
    edit: boolean
    draft: boolean
}

export const RoomEditor = ({ edit, draft }: RoomEditorProps) => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState('room-details');

    const goBack = () => {
        if (history.length > 2) {
            history.goBack();
        } else {
            history.push('/situation-rooms');
        }
    };
    const goToRoom = () => {
        history.push(`/situation-rooms/${data.id}`);
    };
    const { params }: RoomEditorRouteParams = useRouteMatch();
    const { data, loading } = useSituationRoomByID({ id: params.roomId });
    const { data: draftData, loading: draftLoading } = useSituationRoomDraftByID({ id: params.draftId });
    const { user } = useUser();
    const { instance: instanceData, loading: instanceLoading } = useGetInstance({ instanceId: user?.instance?.id });
    const [isVersions, setIsVersions] = useState(false);
    if (loading || draftLoading || instanceLoading) {
        return <Loading />;
    }
    if (!loading && !data && !instanceLoading) {
        const isInactiveInstance = instanceData?.plan?.validUntil ? instanceData?.plan?.validUntil < new Date().getTime() : false;
        if (isInactiveInstance) {
            return (
                <div className="p-4 content-page my-0 d-flex flex-column">
                    <div className="p-4 bg-white rounded-20 d-flex align-items-center justify-content-center flex-1">
                        <div className="d-flex flex-column align-items-center maxw-500">
                            <DashboardDefault />
                            <h2 className="my-2">Inactive license</h2>
                            <p className="text-center">
                                This account belongs to an organisation with an inactive Logically Intelligence license.
                                Creating new Situation Rooms requires an active license. To find out more, please contact support.
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
    }
    return (
        <RoomEditorContextProvider edit={edit} room={data || draftData} draft={draft}>
            <div className="d-flex flex-column flex-1">
                {isVersions ? (
                    <Versions closeVersions={() => setIsVersions(false)}
                        roomData={data}
                        setRedirect={() => goToRoom()}
                    />
                ) : (
                    <>
                        <Header goBack={goBack} />
                        <div className="bg-white d-flex flex-column flex-1">
                            <SplitView right={(<RightPanel />)}
                                activeTab={activeTab}
                                left={(
                                    <div>
                                        <Tabs setActiveTab={setActiveTab} activeTab={activeTab} />
                                        <TabContent data-testid="tab-content" activeTab={activeTab}>
                                            <TabPane tabId="room-details">
                                                <RoomDetails />
                                            </TabPane>
                                            <TabPane tabId="room-creation">
                                                <RoomCreation />
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                )}
                            />
                        </div>
                        <RoomEditorFooter goBack={goBack}
                            goToRoom={goToRoom}
                            setIsVersions={setIsVersions}
                            isExistingRoomDraft={draft && edit}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                    </>
                )}
            </div>
        </RoomEditorContextProvider>
    );
};

import React from 'react';
import { useDispatch } from 'react-redux';
import { getIcons } from '../../../../../../utils/getIcons';
import { largeNumber } from '../../../../../../utils/NumberFormat';
import { WidgetName, WidgetTextTooltip } from '../WidgetTextTooltip';
import { unsetParam } from '../../../../../../utils/urlParams';
import { saveSelectedCluster } from '../../../../store';

type ContentProps = {
    count?: number | string
    setTab?: (a: string) => void
    onClick?: () => void
    disabled?: boolean
    subClusterId?: string
}

export const Content = ({
    count,
    setTab,
    onClick,
    disabled,
    subClusterId
}: ContentProps) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        if (disabled) return;
        if (onClick) return onClick();
        setTab?.('content');
        unsetParam('hasDuplicates');
        dispatch(saveSelectedCluster({
            hasDuplicatesFilter: false
        }));
    };
    return (
        <div className="cluster-stat-container" onClick={handleClick}>
            <WidgetTextTooltip widgetName={WidgetName.Content} />
            <span className="font-weight-bold d-flex align-items-center">{getIcons('contents')}
                <p className={`m-0 ml-1 font-weight-normal ${disabled ? '' : 'btn btn-link d-inline p-0 mw-0'}`}>
                    {largeNumber(count)}
                </p>
            </span>
        </div>
    );
};

import React, { useState } from 'react';
import { Form, Button, Label, FormGroup } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD } from '../../graphql/Mutations';
import { Input } from '../../components/Form/Input';

export const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const [error, setError] = useState('');

    const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
        onCompleted: (res) => {
            const { done, message } = res.forgotPassword;
            if (done) {
                setPasswordResetSent(true);
            } else {
                setError(message);
            }
        }
    });

    const handleEmailSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        if (/\S+@\S+\.\S+/.test(email)) {
            forgotPassword({
                variables: {
                    email
                }
            });
        } else {
            setError('Please enter a valid email address.');
        }
    };

    if (passwordResetSent) {
        return (
            <div>
                <h1>Reset your password</h1>
                <p>If an account exists for <strong>{email}</strong> we&rsquo;ll send link for resetting your password.</p>
                <hr />
                <p>Didn’t get it? Check the email or ask to resend the link.</p>
                <Button color="link" className="p-0" onClick={() => setPasswordResetSent(false)}>Resend the link again</Button>
            </div>
        );
    }

    return (
        <div className="forgot-password">
            <h1>Forgot your password?</h1>
            <p>Enter your email and we&rsquo;ll send you a link to reset your password.</p>
            <hr />
            <Form onSubmit={handleEmailSubmit}>
                <FormGroup className="mb-2">
                    <Label for="email" className="mb-1">Email</Label>
                    <Input value={email}
                        onChange={(e) => { setEmail(e.target.value); setError(''); }}
                        type="email"
                        required
                        error={error}
                        state={error ? 'error' : undefined}
                        testId="emailInput"
                    />
                </FormGroup>
                <Button type="submit" color="primary">Submit</Button>
            </Form>
        </div>
    );
};

import React, { useMemo, useContext, createContext } from 'react';
import Cookies from 'universal-cookie';

const CookiesContext = createContext({});

export const useCookies = () => {
    const context = useContext(CookiesContext);
    if (context === undefined) {
        throw new Error('useCookies be used within a CookiesContextProvider');
    }
    return context;
};

export const CookiesContextProvider = ({ children, mocks }) => {
    let context = useMemo(() => ({
        getCookie: (name) => new Cookies().get(name),
        setCookie: (name, value, options) => new Cookies().set(name, value, options || { path: '/' })
    }), []);

    if (mocks) {
        context = {
            getCookie: mocks.getCookie || context.getCookie,
            setCookie: mocks.setCookie || context.setCookie
        };
    }

    return <CookiesContext.Provider value={context}>{ children }</CookiesContext.Provider>;
};

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    ChevronLeft,
    ChevronRight,
    Minus,
    Plus,
    RefreshCcw,
    X
} from 'react-feather';
import { IconButton } from '../../../../components/Atoms';
import { nodeColors } from '../Utils/sentimentColors';

export type BubbleChartToolbarRef = {
    setZoom: (zoom: number) => void;
}

interface BubbleChartToolbarProps {
    chartZoom: (direction: 'in' | 'out') => void;
    resetZoomAndPan: () => void
}

export const BubbleChartToolbar = forwardRef(({ chartZoom, resetZoomAndPan }: BubbleChartToolbarProps, ref) => {
    const [zoomPercentage, setZoomPercentage] = useState(0);
    const [slide, setSlide] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(sessionStorage.getItem('AITooltipOpen') !== 'false');

    useImperativeHandle(ref, () => ({
        setZoom: (zoom: number) => {
            const currentZoom = Math.max(0.05, Math.min(4, zoom * 1.2));
            let newZoomPercentage = 0;

            if (parseFloat(zoom.toFixed(3)) !== 0.05) {
                newZoomPercentage = Math.round((Math.log(currentZoom / 0.05) / Math.log(4 / 0.05)) * 100);
            }
            if (newZoomPercentage === zoomPercentage) return null;
            setZoomPercentage(newZoomPercentage);
        }
    }));

    return (
        <div className="position-absolute top-right d-flex align-items-stretch justify-content-between px-4 pt-4 w-100">
            {tooltipOpen ? (
                <div className="bg-white border rounded mr-4 flex-1 py-2 px-3 d-flex align-items-center">
                    {tooltipSlides[slide]}
                    <IconButton className="ml-auto"
                        dataTestid="next-tooltip-button"
                        onClick={() => setSlide(slide - 1)}
                        icon={<ChevronLeft />}
                        disabled={slide === 0}
                    />
                    <IconButton className="ml-2"
                        dataTestid="previous-tooltip-button"
                        onClick={() => setSlide(slide + 1)}
                        icon={<ChevronRight />}
                        disabled={slide === tooltipSlides.length - 1}
                    />
                    <IconButton className="ml-2"
                        dataTestid="close-tooltip-button"
                        onClick={() => {
                            setTooltipOpen(false);
                            sessionStorage.setItem('AITooltipOpen', 'false');
                        }}
                        icon={<X />}
                    />
                </div>
            ) : <div className="d-flex" />}
            <div className="bg-white border rounded d-flex align-items-center">
                <div className="p-2 border-right">
                    <IconButton onClick={() => resetZoomAndPan()}
                        dataTestid="reset-zoom-button"
                        icon={<RefreshCcw />}
                    />
                </div>
                <div className="p-2">
                    <IconButton className="mr-2"
                        onClick={() => chartZoom('out')}
                        dataTestid="zoom-out-button"
                        icon={<Minus />}
                    />
                    <span className="user-select-none">{zoomPercentage}%</span>
                    <IconButton className="ml-2"
                        onClick={() => chartZoom('in')}
                        dataTestid="zoom-in-button"
                        icon={<Plus />}
                    />
                </div>
            </div>
        </div>
    );
});

const tooltipSlides = [
    <div className="d-flex align-items-center">
        <span className="line-height-1">Narratives and Narrative Themes are colored based on sentiment</span>
        <div className="status-Active ml-2 mr-1"
            style={{
                border: `1px solid ${nodeColors.positive.nodeBorder}`,
                backgroundColor: nodeColors.positive.combo
            }}
        />
        Positive
        <div className="status-Away ml-2 mr-1"
            style={{
                border: `1px solid ${nodeColors.negative.nodeBorder}`,
                backgroundColor: nodeColors.negative.combo
            }}
        />
        Negative
        <div className="status-not-available ml-2 mr-1"
            style={{
                border: `1px solid ${nodeColors.neutral.nodeBorder}`,
                backgroundColor: nodeColors.neutral.combo
            }}
        />
        Neutral
        <div className="status-not-available ml-2 mr-1"
            style={{
                border: `1px solid ${nodeColors.unknown.nodeBorder}`,
                backgroundColor: nodeColors.unknown.node
            }}
        />
        Unknown
    </div>,
    'Click on a Narrative or a Narrative Theme to find its summary, the constituent content, and some metrics.',
    'Hold Ctrl or ⌘ and scroll to zoom'
];

import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as ActiveState } from 'react-router-dom';
import { useUser } from '../../../context/user/UserContext';
import { TextTooltip } from '../../ToolTip/TextTooltip';
import { Category } from '.';

type NavigationItemsProps = {
    category: Category
}
export const NavigationItems = ({ category }: NavigationItemsProps) => {
    const { notAllowed }: any = useUser();
    const links = category.links.filter(link => !notAllowed.includes(link.id));
    if (!links.length) return null;
    return (
        <>
            {(category.name && category.name !== 'Core') && <p className="category">{category.name}</p>}
            <ul>
                {links.map((item) => {
                    const Icon = item.icon || null || React.Component;
                    return (
                        <NavItem key={item.path}>
                            <NavLink tag={ActiveState}
                                isActive={(match: string) => match}
                                to={item.path}
                                className="nav-link d-flex align-items-center"
                            >
                                {item.icon && <Icon />}
                                <span>{item.name}</span>
                                {item.name === 'AI Narratives'
                                && (
                                    <TextTooltip title="BETA"
                                        className="beta-label font-14"
                                        placement="top-end"
                                        text="We’re testing this functionality before releasing it in full. Happy exploring!"
                                    />
                                )}
                            </NavLink>
                        </NavItem>
                    );
                })}
            </ul>
        </>
    );
};

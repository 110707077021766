/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import moment from 'moment';
import { Options } from 'highcharts';
import { ContentId, VolumeOverTime } from '../../types';
import { color } from '../../../../../utils/getColors';
import { ResponsiveChart } from '../../../../../components/Highcharts';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { ChartsPointsContent } from './ChartPointsContent';

type VolumeOverTimeTypePlotProps = {
    content: VolumeOverTime[]
    contentIds: ContentId[]
}

export const VolumeOverTimeTypePlot = ({ content, contentIds }: VolumeOverTimeTypePlotProps) => {
    const { setModal } = useModal();

    const openContentModal = (point: number) => {
        setModal({
            size: 'xl',
            component: <ChartsPointsContent selectedDate={point} contentIds={contentIds} />
        });
    };
    const contentData = content.map((e) => {
        const time = e.time || e.date;
        const date = time.split('-').reverse().join('-');
        return ({
            y: e.count,
            x: new Date(date).getTime()
        }); }).sort((a, b) => a.x - b.x);

    const options = {
        chart: {
            height: 300,
            plotBorderWidth: 1,
            style: {
                fontFamily: '"Nunito", sans-serif'
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            crosshair: false,
            tickLength: 0,
            labels: {
                format: '{value:%e/%m}'
            },
            gridLineColor: color.grey[300],
            gridLineWidth: 1
        },
        yAxis: {
            title: {
                text: undefined
            },
            gridLineWidth: 1
        },
        series: [{
            data: contentData,
            type: 'spline',
            color: color.blue[500]
        }],
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                },
                events: {
                    click: ({ point }) => {
                        if (point.y) {
                            openContentModal(point.x);
                        }
                    }
                }
            }
        },
        tooltip: {
            useHTML: true,
            backgroundColor: 'white',
            headerFormat: '',
            shared: true,
            formatter() {
                const { x, y } = this as any;
                return `
            <div class="d-flex justify-content-between" style="min-width: max-content">
                <p class="mr-4 font-family-secondary">Date<p/>
                ${moment(x).format('DD MMMM YYYY')}
            </div>
            <hr class="m-0"/>
            <div class="d-flex justify-content-between mb-n1 mt-1" style="min-width: max-content">
                <p class="mr-4 font-family-secondary">Contents<p/>
                ${y}
            </div>
        `; }
        }
    } as Options;

    return (
        <div className="w-100" style={{ maxWidth: 1100 }}>
            <ResponsiveChart options={options} />
        </div>
    );
};

import React, { useState } from 'react';
import {
    Alert,
    Button,
    Form,
    FormGroup,
    Label
} from 'reactstrap';
import { AlertTriangle } from 'react-feather';
import { client } from '../../auth0';
import { PasswordInput } from '../../components/Form/PasswordInput';
import { Input } from '../../components/Form/Input';

type ErrorType ={
    code: string
}

type LoginFormProps = {
    setForgotPasswordFlow: (forgotPass: boolean) => void
    setIsSSOLogin: (isSSO: boolean) => void
}

export const LoginForm = ({ setForgotPasswordFlow, setIsSSOLogin }: LoginFormProps) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const authenticate = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        client.login({
            email,
            password,
            responseType: 'id_token',
            scope: 'openid profile email user_metadata',
            realm: 'Username-Password-Authentication',
            redirectUri: window.location.origin,
        }, (err: ErrorType) => {
            if (err) {
                setError(err.code === 'access_denied' ? `That email and password combination is incorrect, 
                    please try re-enter your email and password.` : 'Something went wrong, please try again.');
            } else {
                window.location.replace(window.location.origin);
            }
        });
    };

    const renderSingleSignOnButton = (className: string) => (
        <Button color="link"
            className={className}
            onClick={() => setIsSSOLogin(true)}
        >
            Use Single Sign-on
        </Button>
    );

    return (
        <Form onSubmit={authenticate} autoComplete="false">
            <Label className="mb-1">Email</Label>
            <Input value={email}
                onChange={(e) => { setEmail(e.target.value); setError(''); }}
                type="email"
                required
                state={error ? 'error' : undefined}
                data-testId="emailInput"
            />
            <FormGroup className="my-2">
                <Label className="mb-1">Password</Label>
                <PasswordInput password={password} setPassword={setPassword} setError={setError} state={!!error} required />
            </FormGroup>
            <Alert color="danger" isOpen={!!error} className="mt-3 text-danger d-flex align-items-center p-2">
                <AlertTriangle className="mr-2" size={error === 'Please enter a valid email address' ? 22 : 40} />
                <p className="text-black m-0">{typeof error === 'string' ? error : 'Something went wrong, please try again.'}</p>
            </Alert>
            <div className="d-flex align-items-center justify-content-between">
                {renderSingleSignOnButton('pl-0 d-none d-xl-block')}
                <div className="d-block d-xl-none" />
                <div className="d-flex justify-self-end">
                    <Button color="link" onClick={() => setForgotPasswordFlow(true)} className="d-block p-0 mr-2">
                        Forgot your password?
                    </Button>
                    <Button type="submit" color="primary" disabled={Boolean(error)}>Continue</Button>
                </div>
            </div>
            {/* responsive single sign-on button */}
            {renderSingleSignOnButton('position-absolute bottom-middle d-xl-none mb-2')}
        </Form>
    );
};

import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Paperclip } from 'react-feather';
import { Button, FormGroup, Input, InputGroup } from 'reactstrap';
import { getRoom } from '../../../utils/variables';
import { Loading } from '../../../components/Loading';
import { getToast } from '../../../utils/getToast';
import { color } from '../../../utils/getColors';

export const CrowdtangleCSV = () => {
    const toast = getToast();
    const [file, setFile] = useState(null);
    const fileUploadChange = (e) => setFile(e.target.files[0]);
    const room = getRoom();
    const [uploadFile, { loading }] = useMutation(UPLOAD_CROWDTANGLE_CSV, {
        variables: {
            projectId: room.project_id,
            file
        },
        onCompleted: ({ uploadCrowdtangleCSV }) => {
            if (uploadCrowdtangleCSV) {
                setFile(null);
                toast.success('File uploaded');
            } else {
                toast.error('File upload failed, please try again');
            }
        }
    });
    return (
        <div className="bg-white p-3">
            <h3 className="m-0">Upload CSV</h3>
            <hr className="my-3" />
            <InputGroup className="d-flex align-items-center mb-3">
                <FormGroup>
                    <span>
                        <label className="mb-0 upload-file cursor-pointer d-flex align-items-center">
                            <Paperclip color={color.blue[500]} size={15} className="mr-11" />
                            <Input onChange={fileUploadChange}
                                type="file"
                                accept=".csv,.xlx,.xlsx"
                                className="d-none"
                                data-testid="file-upload"
                            />
                            <b className="text-primary">Choose file</b>
                        </label>
                    </span>
                </FormGroup>
                <span className="ml-2 d-inline-block rounded">{file ? file.name : 'No file choosen'}</span>
            </InputGroup>
            <div className="d-flex align-items-center">
                <Button color="primary" disabled={!file || loading} onClick={() => uploadFile()} className="mr-3">Upload</Button>
                {loading && <div><Loading relative /></div>}
            </div>
        </div>
    );
};

const UPLOAD_CROWDTANGLE_CSV = gql`
    mutation uploadCrowdtangleCSV($projectId: String!, $file: Upload!) {
        uploadCrowdtangleCSV(projectId: $projectId, file: $file)
    }
`;

import React from 'react';
import { Actor } from '../../types';
import { DuplicatedDocsModal } from '../../DuplicatedDocsModal';
import { useModal } from '../../../../../context/modal/ModalComponent';

type ActorProps = {
    actors: Actor[]
}

export const Actors = ({ actors }: ActorProps) => {
    const { setModal } = useModal();
    const openDuplictedDocsModal = (contentIds: string[], actorName: string) => setModal({
        component: <DuplicatedDocsModal contentIds={contentIds} title={actorName} />,
        size: 'xl'
    });
    return (
        <div className="bg-white border p-2">
            {
                !actors ? <p className="m-0">No data found</p>
                    : actors.map((actor, index) => {
                        if (index > 4) return null;
                        return (
                            <div key={actor.actor} className={index !== 0 ? 'mt-1' : ''}>
                                <p className="btn btn-link d-inline p-0 dont-break-out"
                                    onClick={() => openDuplictedDocsModal(actor.contentIds, actor.actor)}
                                >{actor.actor}
                                </p> - {actor.contentIds.length}
                            </div>
                        ); })
            }
        </div>
    );
};

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Loading } from '../../../../components/Loading';
import { resetSelectedCluster, setActiveListView } from '../../store';
import { getParam, setParam, unsetParam } from '../../../../utils/urlParams';
import { ClustersTable } from './ClustersTable';
import { GET_CLUSTERS_FOR_LIST_VIEW } from '../../../../graphql';
import { Cluster } from '../types';
import { useGlobalFiltersInClusters } from '../../../../services/Clusters/GlobalFIltersInClusters';
// import { getRoom } from '../../../../utils/variables';
// import { ToggleButton } from '../../../../components/Atoms/ToggleButton';
import { EmptyState } from '../../../../components/EmptyState';
import { NoSearchResults } from '../../../../assets/svg/noSearchResults';
import { RootState } from '../../../../store';
// import { SubClustersTable } from './SubClustersTable';

export const VIEW_MODES = {
    THEMES: 'themes',
    NARRATIVES: 'narratives'
};

export type ListViewRef = {
    handleSearch: () => void;
}

type ListViewProps = {
    sort: { field: string, order: string }
}

const itemsPerPage = 10;

export const ListView = forwardRef(({ sort }: ListViewProps, ref) => {
    const { selectedCluster } = useSelector((state: RootState) => state.selectedCluster);
    const dispatch = useDispatch();
    const filters = useGlobalFiltersInClusters({ useGlobalFilters: true });
    // const room = getRoom();
    // const { viewMode } = useSelector((state: RootState) => state.activeAiClusteringTableView);
    const paramClustersPage = parseInt(getParam('clusters-page') || '1', 10);
    // const paramSubClustersPage = parseInt(getParam('sub-clusters-page') || '1', 10);
    const isSubClustersPageParam = getParam('sub-clusters-page') !== null;
    const [clustersOffset, setClustersOffset] = useState((paramClustersPage - 1) * itemsPerPage);
    // const [subClustersOffset, setSubClustersOffset] = useState((paramSubClustersPage - 1) * itemsPerPage);

    useEffect(() => {
        dispatch(setActiveListView({ viewMode: isSubClustersPageParam ? VIEW_MODES.NARRATIVES : VIEW_MODES.THEMES }));
    }, [dispatch, isSubClustersPageParam]);

    const { data: clustersData, loading: clusterLoading } = useQuery(GET_CLUSTERS_FOR_LIST_VIEW, {
        variables: {
            limit: itemsPerPage,
            skip: clustersOffset,
            ...filters,
            sort
        },
        onCompleted: () => {
            if (selectedCluster) {
                setSelectedCluster('');
                dispatch(resetSelectedCluster());
            }
        }
    });
    const getClustersData = clustersData?.getClusters;
    const clusters: Cluster[] = getClustersData?.clusters || [];
    // const { data: subClustersData, loading: subClusterLoading } = useQuery(GET_ALL_SUB_CLUSTERS, {
    //     variables: {
    //         limit: itemsPerPage,
    //         skip: subClustersOffset,
    //         projectId: room.project_id,
    //         filters: { search: filters.search }
    //     },
    //     fetchPolicy: 'cache-and-network',
    //     skip: !clusters?.length
    // });
    // const getSubClustersData = subClustersData?.getAllSubClusters;
    // const subClusters: SubCluster[] = getSubClustersData?.subClusters || [];

    const setSelectedCluster = (id: string) => {
        unsetParam('tab');
        if (!id) {
            return unsetParam('narrative-theme');
        }
        setParam('narrative-theme', id);
    };

    const handleSearch = () => {
        if (selectedCluster) {
            setSelectedCluster('');
            dispatch(resetSelectedCluster());
        }
        setParam('page', '1');
        setClustersOffset(0);
        // setSubClustersOffset(0);
    };

    const handleClustersOffsetChange = (e: number) => {
        setClustersOffset(e);
        unsetParam('sub-clusters-page');
        setParam('clusters-page', ((e / itemsPerPage) + 1).toString());
    };

    // const handleSubClustersOffsetChange = (e: number) => {
    //     setSubClustersOffset(e);
    //     unsetParam('clusters-page');
    //     setParam('sub-clusters-page', ((e / itemsPerPage) + 1).toString());
    // };

    useImperativeHandle(ref, () => ({
        handleSearch
    }));

    if (clusterLoading) {
        return <Loading gif relative height={400} />;
    }

    if (clusters?.length < 1) {
        return (
            <EmptyState title="No data found."
                summary="Please change the search string and try again."
                svgIcon={<NoSearchResults />}
            />
        );
    }

    // const handleOnToggleClick = (value: boolean) => {
    //     dispatch(setActiveListView({ viewMode: value ? VIEW_MODES.NARRATIVES : VIEW_MODES.THEMES }));

    //     if (value) {
    //         handleSubClustersOffsetChange(0);
    //     } else {
    //         handleClustersOffsetChange(0);
    //     }
    // };

    return (
        <div className="ai-narratives-list-view">
            {/* <div className="mb-4">
                <ToggleButton value={viewMode === VIEW_MODES.NARRATIVES} onChange={handleOnToggleClick} />
            </div> */}
            {/* {viewMode === VIEW_MODES.NARRATIVES ? (
                <SubClustersTable subClusters={subClusters}
                    total={getSubClustersData.total}
                    offset={subClustersOffset}
                    onOffsetChange={handleSubClustersOffsetChange}
                    itemsPerPage={itemsPerPage}
                />
            ) : (
                <ClustersTable clusters={clusters}
                    total={getClustersData.total}
                    offset={clustersOffset}
                    onSetSelectedCluster={setSelectedCluster}
                    onOffsetChange={handleClustersOffsetChange}
                    itemsPerPage={itemsPerPage}
                />
            )} */}
            <ClustersTable clusters={clusters}
                total={getClustersData.total}
                offset={clustersOffset}
                onSetSelectedCluster={setSelectedCluster}
                onOffsetChange={handleClustersOffsetChange}
                itemsPerPage={itemsPerPage}
            />
        </div>
    );
});

import React, { useState } from 'react';
import {
    Alert,
    Form,
    Input,
    Button,
    Label,
    FormGroup
} from 'reactstrap';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery, gql } from '@apollo/client';
import { Loading } from '../../components/Loading';
import { client } from '../../auth0';
import { AuthLayout } from '../Login/AuthLayout';
import { PasswordInput } from '../../components/Form/PasswordInput';

export const GET_INVITE = gql`
  query getInvite($code: String) {
    getInvite(code: $code) {
      email
      instance
      expiryDate
    }
  }
`;

const SIGN_UP = gql`
  mutation signUpAuth0($data: SignUpInput) {
    signUpAuth0(data: $data) {
      done
      message
    }
  }
`;

export const SignUp = () => {
    const match = useRouteMatch();
    const { data, loading } = useQuery(GET_INVITE, {
        variables: {
            code: match.params.invite,
        },
    });
    const [error, setError] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmation, setConfirmation] = useState('');
    const [loadingB, setLoading] = useState(false);
    const [signUpComplete, setSignUpComplete] = useState(false);
    const invite = data ? data?.getInvite[0] : false;
    const [signUp] = useMutation(SIGN_UP, {
        onCompleted: res => {
            if (res?.signUpAuth0?.done) {
                setSignUpComplete(true);
            } else {
                setError(res?.signUpAuth0?.message);
            }
            setLoading(false);
        },
    });

    const expired = new Date() - new Date(invite?.expiryDate) > 0;

    const handleSubmit = e => {
        e.preventDefault();
        if (expired) return;

        if (password.length < 8) {
            setError('Password must be 8 characters or longer.');
        } else if (password !== confirmation) {
            setError('New password and Confirm password do not match.');
        } else {
            setLoading(true);
            signUp({
                variables: {
                    data: {
                        code: match.params.invite,
                        email: invite.email,
                        password,
                        firstName,
                        lastName,
                        instance: invite.instance,
                    },
                },
            });
        }
    };

    const authenticate = async () => client.login(
        {
            email: invite.email,
            password,
            responseType: 'id_token',
            scope: 'openid profile email user_metadata',
            realm: 'Username-Password-Authentication',
            redirectUri: window?.location.origin,
        },
        () => {
            window?.location.replace(window?.location.origin);
        }
    );

    if (signUpComplete) {
        return (
            <AuthLayout>
                <h1>Great! You&rsquo;re all set.</h1>
                <p>Your account has been created successfully. You can access Logically intelligence now.</p>
                <Button onClick={authenticate} color="primary">Continue to Logically Intelligence</Button>
            </AuthLayout>
        );
    }

    return (
        <AuthLayout>
            {(loading || loadingB) && <Loading background="rgba(250,250,250,.5)" />}
            <h1>Sign Up</h1>
            <p>Fill the details below to access your account.</p>
            <hr />
            <p>* The password must have at least 8 characters.</p>
            <hr />
            {!loading && !invite && <Redirect to="/" />}
            {invite && (
                <Form onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup>
                        <Label className="mb-1">Email</Label>
                        <Input type="email"
                            name="email"
                            value={invite.email}
                            disabled
                            className="bg-gray"
                        />
                    </FormGroup>

                    {!expired && (
                        <FormGroup className="mt-1">
                            <Label className="mb-1">First name</Label>
                            <Input name="firstname"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                required
                            />
                        </FormGroup>
                    )}

                    {!expired && (
                        <FormGroup className="mt-1">
                            <Label className="mb-1">Last name</Label>
                            <Input name="lastname"
                                type="text"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                required
                            />
                        </FormGroup>
                    )}

                    {!expired && (
                        <FormGroup className="mt-1">
                            <Label className="mb-1">Password</Label>
                            <PasswordInput password={password}
                                setPassword={setPassword}
                                required
                                testId="password1"
                            />
                        </FormGroup>
                    )}

                    {!expired && (
                        <FormGroup className="mt-1">
                            <Label className="mb-1">Confirm password</Label>
                            <PasswordInput password={confirmation}
                                setPassword={setConfirmation}
                                required
                                testId="password2"
                            />
                        </FormGroup>
                    )}

                    {!expired && (
                        <Button type="submit" color="primary" className="mt-2" data-testid="clicked">
                            Sign up
                        </Button>
                    )}

                    <Alert color="danger"
                        className="mt-4"
                        isOpen={error || expired}
                        toggle={() => {
                            setError('');
                        }}
                    >
                        {expired ? 'Your invite has expired, please request another invite' : error}
                    </Alert>
                </Form>
            )}
        </AuthLayout>
    );
};

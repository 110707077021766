import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { ArrowLeft } from 'react-feather';
import { AuthLayout } from './AuthLayout';
import { SSOLogin } from './SSOLogin';
import { LoginForm } from './LoginForm';
import { ForgotPassword } from './ForgotPassword';
import { gql } from '../../generated';
import { getParam } from '../../utils/urlParams';

export const Login = () => {
    const [forgotPasswordFlow, setForgotPasswordFlow] = useState(false);
    const [isSSOLLogin, setIsSSOLogin] = useState(!!getParam('ssocn'));

    return (
        <AuthLayout>
            {forgotPasswordFlow ? (
                <>
                    <Button color="link" className="d-flex align-items-center mb-2 p-0" onClick={() => setForgotPasswordFlow(false)}>
                        <ArrowLeft size={18} />
                        <p className="my-0 ml-2 text-primary">Back to login</p>
                    </Button>
                    <ForgotPassword />
                </>
            ) : (
                <>
                    <h1>Login {isSSOLLogin ? 'with Single Sign-On' : ''}</h1>
                    <p>Enter your details to continue.</p>
                    <hr />
                    {isSSOLLogin ? <SSOLogin setIsSSOLogin={setIsSSOLogin} />
                        : (
                            <LoginForm
                                setForgotPasswordFlow={setForgotPasswordFlow}
                                setIsSSOLogin={setIsSSOLogin}
                            />
                        )}
                </>
            )}
        </AuthLayout>
    );
};

export const GET_CONNECTION_DETAILS = gql(`
    query getConnectionDetails($email: String!) {
        getConnectionDetails(email: $email) {
            connectionName
        }
    }
`);

import React, { useRef, useCallback } from 'react';
import { Input, Button, Popover } from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { User } from '../../../assets/svg/user';
import { useUser } from '../../../context/user/UserContext';
import { useDropDown } from '../../../utils/useDropdown';

export const ProfileImage = ({ updateProfile }) => {
    const { user } = useUser();
    const [dropdown, toggleDropdown, setDropdown] = useDropDown();
    const popoverRef = useRef(null);
    const buttonRef = useRef(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        updateProfile({
            variables: {
                data: {
                    id: user.uid,
                    picture: file,
                },
            },
        });
    };

    const handleClickOutside = useCallback((event) => {
        if (
            popoverRef.current
            && !popoverRef.current.contains(event.target)
            && buttonRef.current
            && !buttonRef.current.contains(event.target)
        ) {
            setDropdown(false);
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [setDropdown]);

    const handleToggleDropdown = () => {
        if (!dropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        toggleDropdown();
    };

    return (
        <div>
            <div className="position-relative d-inline-block">
                {user.photoURL ? (
                    <img src={user.photoURL}
                        alt="logically user"
                        className="rounded-circle"
                        style={{ width: 130 }}
                    />
                ) : (
                    <span className="mx-1">
                        <User size="106" />
                    </span>
                )}
                <div className="absolute-bottom-right">
                    <Button className="p-2 border rounded-circle mw-0"
                        id="profile-image-popover"
                        onClick={handleToggleDropdown}
                        innerRef={buttonRef}
                    >
                        <FeatherIcon.Camera size={16} className="d-block" />
                    </Button>
                </div>
            </div>
            <Input type="file"
                className="d-none"
                id="profile-image-input"
                accept="image/x-png,image/gif,image/jpeg"
                onChange={handleImageChange}
            />
            <Popover isOpen={dropdown}
                toggle={toggleDropdown}
                placement="right-end"
                target="profile-image-popover"
                hideArrow
                innerRef={popoverRef}
            >
                <Button onClick={() => {
                    document.getElementById('profile-image-input').click();
                }}
                >
                    Upload new picture
                </Button>
            </Popover>
        </div>
    );
};

import React from 'react';
import { Disc, List } from 'react-feather';
import { Button } from 'reactstrap';
import { useTour } from '@reactour/tour';
import { TooltipItem } from '../../../components/ToolTip';
import { useAppDispatch } from '../../../store';
import { resetSelectedCluster, setActiveTab } from '../store';
import { TextTooltip } from '../../../components/ToolTip/TextTooltip';
import { setParam, unsetParam } from '../../../utils/urlParams';
import { color } from '../../../utils/getColors';

type TopActionBarProps = {
    listView: boolean
    counts?: {
        clusters: number
        subClusters: number
    }
}

export const TopActionBar = ({ listView, counts }: TopActionBarProps) => {
    const dispatch = useAppDispatch();
    const { setIsOpen } = useTour();
    return (
        <div className="pt-2 d-flex justify-content-between position-relative z-index">
            <div className="d-flex align-items-center">
                <p className="font-weight-bold m-0">AI Narratives</p>
                <TooltipItem id="aiClustering"
                    placement="bottom-start"
                    className="bg-white text-black"
                    content={(
                        <div>
                            <p>AI narratives uses Generative AI to sort and summarise vast numbers of documents into
                                different groups based on their context and overall themes.
                            </p>
                            <p><strong>Narrative Theme:</strong> Narratives grouped into themes based on similarities and commonalities.</p>
                            <p><strong>Narrative:</strong> Posts grouped into narratives based on detected patterns,
                                sentiments and relationships.
                            </p>
                            <hr color="white" />
                            <Button color="link" className="p-0" onClick={() => setIsOpen(true)}>Feature tour</Button>
                            <Button className="ml-1" color="link">
                                <a href="https://logically-intelligence.readme.io/docs/ai-narratives"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="d-inline"
                                >
                                    <span>Learn more</span>
                                </a>
                            </Button>
                        </div>
                    )}
                />
                <TextTooltip title="BETA"
                    id="beta-labels"
                    className="beta-label-summary p-0"
                    placement="bottom-start"
                >
                    <p className="m-0">We’re testing this functionality before releasing it in full.<br /> Happy exploring!</p>
                </TextTooltip>
                {(counts && counts.clusters) && (
                    <span className="ml-3">
                        <p className="m-0">
                            Narrative themes identified: <strong>{counts.clusters}</strong> |
                            Narratives identified: <strong>{counts.subClusters}</strong>
                        </p>
                    </span>
                )}
            </div>
            <div className="d-flex alig-items-center bg-white set-view-type">
                <TextTooltip id="list-labels"
                    placement="bottom-start"
                    title="List view"
                    withIcon
                >
                    <div className={`p-1 ${listView && 'bg-primary'} rounded-sm rounded-right-0`}
                        onClick={() => {
                            dispatch(setActiveTab({ isListViewEnabled: true }));
                            setParam('view', 'list');
                            dispatch(resetSelectedCluster());
                        }}
                        data-testid="list-view"
                    >
                        <List color={`${listView ? 'white' : color.blue[500]}`} />
                    </div>
                </TextTooltip>
                <TextTooltip id="graph-labels"
                    placement="bottom-start"
                    title="Graph view"
                    withIcon
                >
                    <div className={`p-1 ${!listView && 'bg-primary'} rounded-sm rounded-left-0`}
                        onClick={() => {
                            dispatch(setActiveTab({ isListViewEnabled: false }));
                            setParam('view', 'chart');
                            unsetParam('page');
                            unsetParam('tab');
                        }}
                        data-testid="visualization-view"
                    >
                        <Disc color={`${!listView ? 'white' : color.blue[500]}`} />
                    </div>

                </TextTooltip>
            </div>
        </div>
    );
};

import { useQuery } from '@apollo/client';
import { merge, omit } from 'lodash';
import { useGlobalFiltersInClusters } from './GlobalFIltersInClusters';
import { GET_CLUSTERS_COUNT } from '../../graphql';

type UseTotalClustersProps = {
    appliedFilters?: any,
    onCompletedCall?: any,
    useGlobalFilters?: boolean,
    skip?: boolean
}

export const useTotalClusters = ({ appliedFilters, onCompletedCall, useGlobalFilters = true, skip = false }: UseTotalClustersProps) => {
    const filters = useGlobalFiltersInClusters({ useGlobalFilters });
    const { data, loading } = useQuery(GET_CLUSTERS_COUNT, {
        variables: {
            ...filters,
            filters: omit(merge(filters.filters, appliedFilters?.filters || {}), ['labels'])
        },
        skip,
        onCompleted: () => {
            if (onCompletedCall) { onCompletedCall(); }
        }
    });
    return {
        counts: data?.getClustersCount,
        loading
    };
};

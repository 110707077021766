import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useQueryExtracts } from '../../../../../../../services/Content/getQueryExtracts';
import { useTranslateMultipleText } from '../../../../../../../services/google/translate';
import { TranslateButton } from '../../../../../../../components/Atoms/TranslateButton';
import { TranslatedFromLanguage } from '../../../../../../../utils/TranslatedFromLanguage';
import { truncateString } from '../../../../../../../utils/text';
import { LabelDropdown } from '../../../../../Labels';
import { LabelsDisplay } from '../../../../../Labels/LabelsDisplay';
import { getRoom } from '../../../../../../../utils/variables';

export const ThreatContent = ({ content, fromModal, toggleThreat, selected }) => {
    const textLimit = 550;
    const room = getRoom();
    const [showLanguages, setShowLanguages] = useState(false);
    const contentData = { ...content };
    const contentText = (content?.body || content?.text)?.replace(/(?:https?):\/\/[\n\S]+./gim, '');
    const { highlightedText } = useQueryExtracts({
        url: content?.url,
        text: contentText,
    });
    const { getTranslation, data: translated, loading: translateLoading } = useTranslateMultipleText({
        text: highlightedText
    });

    const finalText = translated.length ? translated[0].translations[0] : highlightedText;

    const translation = (e) => {
        e.stopPropagation();
        e.preventDefault();
        getTranslation();
        setShowLanguages(!showLanguages);
    };
    return (
        <div>
            <div className="d-flex justify-content-end align-items-center">
                {fromModal && (
                    <div className="d-flex mb-1 align-items-center justify-content-end">
                        <TranslateButton onClick={translation}
                            className="mr-2"
                            lang={content?.langVerbose}
                            detectedLangs={contentData?.detectedLangs}
                            showText
                        />
                        <LabelDropdown contentId={content.id} roomId={room.id} />
                        {!selected ? (
                            <Button color="primary" className="ml-1" onClick={() => toggleThreat(content, selected)}>
                                Add to report
                            </Button>
                        ) : (
                            <Button color="danger" className="ml-1" onClick={() => toggleThreat(content, selected)}>
                                Remove from report
                            </Button>
                        )}
                    </div>
                )}
            </div>
            <div>
                <LabelsDisplay roomId={room?.id} contentId={content.id} />
                <p className="highlight-em dont-break-out d-inline">
                    {showLanguages && !translateLoading && (
                        <TranslatedFromLanguage languageVerbose={content?.langVerbose} />
                    )}
                    {truncateString(finalText, textLimit)}&nbsp;
                </p>
            </div>
        </div>
    );
};

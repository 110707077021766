import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { useRemoveRestoreCluster } from '../../../../../services/Clusters/removeRestore';

type RemoveConfirmationProps = {
    id: string
    isParentCluster: boolean
    onRemoved?: () => void
}

export const RemoveConfirmation = ({ id, isParentCluster, onRemoved }: RemoveConfirmationProps) => {
    const { closeModal } = useModal();
    const { handleSendtoRemoveContent, loading } = useRemoveRestoreCluster();
    const deleteClusterOrSubCluster = () => handleSendtoRemoveContent([id], isParentCluster ? 'cluster' : 'subcluster');
    return (
        <>
            <h3>Are you sure that you want to remove this {isParentCluster ? 'narrative theme'
                : 'narrative'}?
            </h3>
            <hr />
            <p>The selected {isParentCluster ? 'narrative theme ' : 'narrative '}
                will be fully removed from this situation room.
            </p>
            <hr />
            <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button disabled={loading} color="danger" onClick={deleteClusterOrSubCluster}>Remove</Button>
        </>
    );
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { saveSelectedCluster } from '../../store';
import { Cluster } from '../types';
import { addHighlights, useQueryKeywords } from '../../../../services/Content/getQueryExtracts';
import { getHighlightedContent } from '../../../../utils/getHighlightedContent';
import { ActionDropdown } from '../Utils/ActionDropdown';
import { truncateString } from '../../../../utils/text';
import { Engagement } from '../SelectedClusterView/Summary/ClusterStats/Engagement';
import { Content } from '../SelectedClusterView/Summary/ClusterStats/Content';
import { Duplicates } from '../SelectedClusterView/Summary/ClusterStats/Duplicates';
import { SentimentCard } from '../SelectedClusterView/Summary/ClusterStats/SentimentCard';
import { SelectedClusters } from './ClustersTable';
import { calculateAverageSentiment } from '../../../../utils/calculateAverageSentiment';
import { SubClusters } from '../SelectedClusterView/Summary/ClusterStats/SubClusters';
import { setParam } from '../../../../utils/urlParams';
import { RootState } from '../../../../store';
import { getRoom } from '../../../../utils/variables';
import { RestoreOrRemoveNarrativesButton } from './RestoreOrRemoveNarrativesButton';
import { CompareButtons, CompareButtonType } from '../Utils/CompareButtons';
import { convertToDate } from '../../../../utils/getTimeFrom';
import { WatchlistButton } from '../Utils/WatchlistButton';

type ListViewRowProps = {
    setSelectedCluster: (id: string) => void
    cluster: Cluster
    selectedClusters?: SelectedClusters[]
    onSetSelectedClusters?: (values: SelectedClusters[]) => void
    noActionsAllowed?: boolean
    isCompareModal?: boolean
    compact?: boolean
    isFromRemoveContent?: boolean
    handleRecover: (id: string) => void
    handleRemove: (id: string, status: string) => void
    restoreOrRemoveLoading?: boolean

}

const textLengthLimit = 300;
const expandedTextLimit = 999999;

export const ListViewRow = ({
    setSelectedCluster,
    cluster,
    onSetSelectedClusters,
    selectedClusters = [],
    noActionsAllowed,
    isCompareModal = false,
    compact,
    isFromRemoveContent,
    handleRecover,
    handleRemove,
    restoreOrRemoveLoading = false
}: ListViewRowProps) => {
    const dispatch = useDispatch();
    const keywordsToHighlight = useQueryKeywords('clusterFilters');
    const [textLimit, setTextLimit] = useState(textLengthLimit);
    const checked = selectedClusters.map(x => x.id).indexOf(cluster.id) === -1;
    const { selectedCluster: selectedNarrativeTheme } = useSelector((state: RootState) => state.selectedCluster);

    const { highlightedText: description } = addHighlights({
        text: cluster.description,
        keywordsToHighlight
    });

    const handleCheckboxChange = () => {
        if (!onSetSelectedClusters) return;
        if (!checked) {
            return onSetSelectedClusters(
                selectedClusters.filter(item => item.id !== cluster.id)
            );
        }

        return onSetSelectedClusters([
            ...selectedClusters,
            {
                id: cluster.id,
                isWatching: cluster.isWatching,
                status: cluster.status
            }
        ]);
    };
    const handleTextLimitChange = () => {
        setTextLimit(textLimit === expandedTextLimit ? textLengthLimit : expandedTextLimit);
    };
    const selectCluster = (hasDuplicatesFilter = false) => {
        setSelectedCluster(cluster.id);
        dispatch(saveSelectedCluster({
            selectedCluster: cluster.id,
            isParentCluster: true,
            hasDuplicatesFilter
        }));
    };
    const { highlightedText } = addHighlights({
        text: cluster.title,
        keywordsToHighlight
    });

    const room = getRoom();

    const clusterLink = `/situation-rooms/${room.id}/ai-narratives/narrative-theme/${cluster.id}`;

    const handleClick = (e: React.MouseEvent, tab?: string, hasDuplicates?: boolean) => {
        e.preventDefault();
        selectCluster(hasDuplicates);
        if (tab) {
            setParam('tab', tab);
        }
        if (hasDuplicates) {
            setParam('hasDuplicates', 'true');
        }
    };
    const showCheckbox = (!noActionsAllowed && !compact && !isCompareModal) || isFromRemoveContent;
    const showExtraButtons = !isFromRemoveContent && !compact && !noActionsAllowed && !selectedClusters?.length;
    const updatedDate = moment.utc(convertToDate(parseInt(cluster.updatedDate, 10))).format('DD/MM/YYYY @ HH:mm:ss z');

    return (
        <tr key={cluster.id} className={(selectedNarrativeTheme === cluster.id) ? 'selected' : ''}>
            {showCheckbox && (
                <td className="pr-0 pl-2 align-top">
                    <CustomInput
                        type="checkbox"
                        checked={!checked}
                        id={`contentTableViewItem-${cluster.id}`}
                        data-testid="list-checkbox-input"
                        onChange={handleCheckboxChange}
                    />
                </td>
            )}
            <td className="p-2">
                <div id={`cluster-view-${cluster.id}`}>
                    <div className={`d-flex align-items-top justify-content-between ${compact ? '' : 'pb-2'}`}>
                        <p onClick={() => !isFromRemoveContent && selectCluster()}
                            className={`m-0 highlight-em mw-750 highlight-em 
                        ${isFromRemoveContent ? 'font-weight-bold' : 'text-primary cursor-pointer'}`}
                        >
                            {isFromRemoveContent ? <>{getHighlightedContent(highlightedText)} </> : (
                                <Link to={clusterLink} onClick={handleClick} className="btn-link">
                                    {getHighlightedContent(highlightedText)}
                                </Link>
                            )}

                        </p>
                        {showExtraButtons && (
                            <div className="d-flex justify-content-end align-items-center content-top-bar">
                                <CompareButtons clusterIds={[cluster.id]}
                                    clusterTitles={[cluster.title]}
                                    type={CompareButtonType.AddRemoveButton}
                                    container={`cluster-view-${cluster.id}`}
                                    id={`cluster-view-${cluster.id}_comparison`}
                                    isCompareModal={isCompareModal}
                                    isParent
                                />
                                <WatchlistButton id={cluster.id}
                                    isWatching={cluster.isWatching}
                                    type="cluster"
                                    containerId={`cluster-view-${cluster.id}`}
                                    classnames="ml-1"
                                />
                                <ActionDropdown id={cluster.id}
                                    defaultTitle={cluster.title}
                                    defaultSummary={cluster.description}
                                    containerId={`cluster-view-${cluster.id}`}
                                />
                            </div>
                        )}
                    </div>
                    {!compact && (
                        <Col lg={8} className="mw-750 px-0 pb-2">
                            <p className="highlight-em dont-break-out d-inline">
                                {getHighlightedContent(truncateString(description, textLimit))}&nbsp;
                            </p>
                            {(description.length > textLimit) && (
                                <Button color="link" className="p-0 mw-0" onClick={handleTextLimitChange}>Read more</Button>
                            )}
                            {(textLimit === expandedTextLimit) && (
                                <Button color="link" className="p-0 mw-0" onClick={handleTextLimitChange}>Read less</Button>
                            )}
                        </Col>
                    )}
                    {!compact && (
                        <div className="cluster-stats-align m-0">
                            <Engagement count={cluster.engagement} disabled />
                            {isFromRemoveContent
                                ? (
                                    <>
                                        <SubClusters disabled
                                            count={cluster.subClustersMappedId.length}
                                        />
                                        <Content count={cluster.volume}
                                            disabled
                                        />
                                        <Duplicates count={cluster.duplicatedDocsCount}
                                            disabled
                                        />
                                    </>
                                )
                                : (
                                    <>
                                        <Link to={`${clusterLink}?tab=narrative-clusters`}
                                            onClick={(e) => handleClick(e, 'narrative-clusters')}
                                        >
                                            <SubClusters count={cluster.subClustersMappedId.length} />
                                        </Link>
                                        <Link to={`${clusterLink}?tab=content`} onClick={(e) => handleClick(e, 'content')}>
                                            <Content count={cluster.volume} />
                                        </Link>
                                        <Link to={`${clusterLink}?tab=content&hasDuplicates=true`}
                                            onClick={(e) => handleClick(e, 'content', true)}
                                        >
                                            <Duplicates count={cluster.duplicatedDocsCount} />
                                        </Link>

                                    </>
                                )}
                            <SentimentCard sentiments={cluster.sentimentJson}
                                averageSentiment={calculateAverageSentiment(cluster.sentimentJson)}
                                id={`sentiment_${cluster.id}`}
                            />
                        </div>
                    )}
                </div>
            </td>
            {isFromRemoveContent && selectedClusters.length === 0 && (
                <>
                    <td className="px-3 align-top">
                        <p>{cluster.removedBy}</p>
                        <p>{updatedDate}</p>
                    </td>
                    <td className="px-3 align-top">
                        <RestoreOrRemoveNarrativesButton handleRecover={() => handleRecover(cluster.id)}
                            handleRemove={() => handleRemove(cluster.id, cluster.status)}
                            restoreOrRemoveLoading={restoreOrRemoveLoading}
                        />

                    </td>
                </>
            )}
        </tr>
    );
};

import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { Loading } from '../../../components/Loading/index';
import { SearchFeedback } from '../../../components/SearchFeedback';
import { EmptySearchResults } from '../../../components/EmptySearchResults';
import { Layout } from './Layout';
import { OverviewContextProvider, useOverview } from '../../../context/overview/OverviewContext';
import { OverviewEditBar } from './OverviewEditBar';
import { OverviewTopBar } from './OverviewTopBar';
import { RemovedWidgets } from './RemovedWidgets';
import { CONTENT_TYPE } from '../../../utils/contentTypes';
import { useTotalMatches } from '../../../services/Overview/totalMatches';
import { SearchBarNew } from '../../../components/NewFilters';
import { SearchBar } from '../../../components/Search';
import { getRoom } from '../../../utils/variables';
import { saveFilters } from '../store';
import { useCountOfContentAwatingEnhancement } from '../../../services/Overview/countOfContentAwatingEnhancement';

export const Overview = ({ bread, data }) => {
    const { data: roomOverview, loading: roomOverviewLoading } = useQuery(GET_ROOM_OVERVIEW, {
        variables: {
            roomId: data.situationRoom.id
        },
        fetchPolicy: 'no-cache'
    });

    const { totalMatches, loading: totalMatchesLoading } = useTotalMatches();

    const { awaitingEnhancementCount } = useCountOfContentAwatingEnhancement();

    if (roomOverviewLoading || totalMatchesLoading) {
        return <Loading gif height="100vh" pointer={false} />;
    }
    return (
        <OverviewContextProvider defaultLayout={roomOverview?.getRoomOverview}
            canEdit={totalMatches !== 0}
            awaitingEnhancementCount={awaitingEnhancementCount}
        >
            <OverviewTopBar awaitingEnhancementCount={awaitingEnhancementCount} />
            <div className="page-padding">
                <OverviewInner bread={bread} totalMatches={totalMatches} />
            </div>
        </OverviewContextProvider>
    );
};

export const GET_ROOM_OVERVIEW = gql`
    query getRoomOverview($roomId: ID!) {
        getRoomOverview(roomId: $roomId) {
            situationRoom
            data {
                id
                type
                x
                y
                w
                h
                minH
                minW
                maxH
                maxW
                data {
                    type
                    size
                    title
                    columns
                    items {
                        type
                        id
                        title
                    }
                    percentage
                    fields
                }
                removed
                dateRemoved
            }
        }
    }
`;

const OverviewInner = ({ bread, totalMatches, error }) => {
    const { edit, hasChanges } = useOverview();
    const dispatch = useDispatch();
    const parseFilters = sessionStorage.getItem('initialFilters');
    const initialFilters = parseFilters ? JSON.parse(parseFilters) : null;
    const room = getRoom();

    if (initialFilters) {
        dispatch(saveFilters({ ...initialFilters, roomId: room?.id }));
        sessionStorage.removeItem('initialFilters');
    }
    const filters = useSelector((state) => state.filters.filters);
    const hasFilters = filters.origin?.length > 0
      || filters.sentiment?.length > 0
      || filters.sources?.length > 0
      || filters.dateRange?.startDate
      || filters.query?.length > 0;
    if (!totalMatches && !hasFilters && !filters.convertedBooleanQuery?.boosting?.positive?.bool) {
        return (
            <div>
                {bread}
                <EmptySearchResults />
            </div>
        );
    }

    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;

    return (
        <div>
            <Prompt when={edit && hasChanges} message="Are you sure want to exit the screen? Changes you made will not be saved." />
            {edit && (
                <>
                    <OverviewEditBar />
                    <div className="overview-spacer" />
                    <RemovedWidgets />
                </>
            )}
            <div className="d-flex justify-content-between align-items-start">
                {bread}
            </div>
            <h2 className="mt-0 mb-3">Overview</h2>
            <div className={edit ? 'pointer-events-none' : ''}>
                {betaFiltersEnabled ? <SearchBarNew overview showBooleanSearch /> : <SearchBar name="Overview Filters" showBooleanSearch />}
            </div>
            <div className="mb-3 mt-1">
                <SearchFeedback totalCount={totalMatches} error={error} contentType={CONTENT_TYPE.MATCH} />
            </div>
            {totalMatches === 0 ? (
                <EmptySearchResults mainHeading="We couldn't find any results"
                    subtitle="Try adjusting your search or filter options"
                    filters
                />
            ) : (
                <Layout />
            )}
        </div>
    );
};

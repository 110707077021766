import { delay, isEqual } from 'lodash';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from 'react';
import { Chart } from 'regraph';
import { scaleLinear } from 'd3';
import { useDispatch, useSelector } from 'react-redux';
import { ClusterForChartWithSubClusters, SubClusterForChart } from '../types';
import { calculateAverageSentiment } from '../../../../utils/calculateAverageSentiment';
import { largeNumber } from '../../../../utils/NumberFormat';
import { saveSelectedCluster } from '../../store';
import { getIcons } from '../../../../utils/getIcons';
import { RootState } from '../../../../store';
import { BubbleChartToolbar, BubbleChartToolbarRef } from './Toolbar';
import { nodeColors } from '../Utils/sentimentColors';
import { getParam, setParam } from '../../../../utils/urlParams';
import { color as allColors } from '../../../../utils/getColors';
import { getRoom } from '../../../../utils/variables/room';

type BubbleChartProps = {
    clusters: ClusterForChartWithSubClusters[]
}

type SentimentName = 'positive' | 'negative' | 'neutral';

const minZoom = 0.05;
const maxZoom = 4;
const zoomThresholdToShowSubClusters = 0.1;

const scaleZoom = scaleLinear().domain([5, 50, 80, 100, 170, 275, 300])
    .range([0.3, 0.25, 0.2, 0.15, 0.101, 0.101]);

export const BubbleChart = ({ clusters }: BubbleChartProps) => {
    const dispatch = useDispatch();
    const chartRef = useRef<Chart>(null);
    const tooltipRef = useRef(null);
    const toolbarRef = useRef<BubbleChartToolbarRef>(null);

    const room = getRoom();
    const aiNarrativeCoordinates = room.instance?.plan?.others?.aiNarrativeCoordinates;
    const defaultPositions = aiNarrativeCoordinates ? getPositions(clusters) : {};
    const [positions, setPositions] = useState(defaultPositions);

    const [showSubClusters, setShowSubclusters] = useState(false);
    const [chartView, setView] = useState<Chart.Props['view']>(undefined);
    const [initialChartView, setInitialView] = useState<Chart.Props['view']>(undefined);
    const { selectedCluster } = useSelector((state: RootState) => state.selectedCluster);
    useEffect(() => {
        if (!selectedCluster && !isEqual(chartView, initialChartView)) {
            setView(initialChartView);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCluster]);
    const sizes = clusters.map(({ subClusters }) => subClusters).flat().map(({ volume }) => volume);
    const scaleDomain = [Math.min(...sizes), Math.max(...sizes)];
    const items = Object.fromEntries(
        clusters.map((cluster) => {
            const notSelected = Boolean(selectedCluster && selectedCluster !== cluster.id);
            return getSubClusterItems({
                subClusters: cluster.subClusters,
                clusterId: cluster.id,
                scaleDomain,
                showSubClusters,
                notSelected,
                selectedCluster
            });
        }).flat()
    );

    const combineNodesHandler = ({ setStyle, combo }: any) => {
        const cluster = clusters.find(({ id }) => id === combo.clusterId);
        if (!cluster) return null;
        const notSelected = selectedCluster && selectedCluster !== cluster.id;
        const opacity = notSelected ? 0.3 : 1;
        const sentiment = calculateAverageSentiment(cluster.sentimentJson) as SentimentName;
        const color = nodeColors[sentiment].combo.replace(')', `, ${opacity})`).replace('rgb(', 'rgba(');
        setStyle({
            color,
            border: { color },
            label: {
                text: showSubClusters ? '' : cluster.volume,
                position: { horizontal: 'center', vertical: 'middle' },
                fontSize: 120,
                backgroundColor: 'transparent'
            },
            arrange: {
                tightness: cluster.subClusters.length > 1 ? 8 : 2,
                stretchType: 'auto',
                packing: 'circle',
                name: 'concentric'
            }
        });
    };

    const updateTooltip = ({ id, x, y }: any) => {
        if (!tooltipRef.current) return;
        (tooltipRef.current as any).setTooltip({ id, x, y });
    };

    const getIdToSearch = (id: string) => {
        let isParent = id.includes('_combonode_');
        /* if isParent is false and showSubClusters is false,
        we need to set isParent to true and use the cluster ID instead */
        let idToSearch = id.replace('_combonode_', '');
        if (!isParent && !showSubClusters) {
            const cluster = clusters.find(({ subClusters }) => subClusters.find((subCluster) => id === subCluster.id));
            if (!cluster) return null;
            isParent = true;
            idToSearch = cluster.id;
        }

        return {
            isParent,
            idToSearch,
            idToFind: `${isParent ? '_combonode_' : ''}${idToSearch}`
        };
    };

    const getCoordinates = ({ id, x, y }: { id: string, x: number, y: number }) => {
        if (!chartRef.current) return;

        const ids = getIdToSearch(id);
        if (!ids) return;

        const itemViewCoordinates = chartRef.current.getViewCoordinatesOfItem(ids.idToFind);
        const coordinates = itemViewCoordinates || { x, y };
        return {
            ...coordinates,
            isOffScreen: !itemViewCoordinates
        };
    };

    const getPanLocation = ({ id, x, y }: { id: string, x: number, y: number }) => {
        const coordinates = getCoordinates({ id, x, y });
        if (!coordinates || !chartRef.current) return;
        const { width, height } = (chartRef.current as any).containerRef.current.getBoundingClientRect();

        const newView = chartRef.current.props.view;
        if (!newView || !('offsetX' in newView)) return;

        return {
            offsetX: newView.offsetX + width / 1.15 - coordinates.x,
            offsetY: newView.offsetY + height / 2 - coordinates.y,
            isOffScreen: coordinates.isOffScreen,
            zoom: newView.zoom
        };
    };

    const getZoomValue = ({ id }: { id: string }): number => {
        if (!chartRef.current) return minZoom;
        const ids = getIdToSearch(id);
        if (!ids) return minZoom;
        const itemInfo = chartRef.current.getItemInfo(ids.idToFind);
        if (!itemInfo) return minZoom;
        const item = 'item' in itemInfo ? itemInfo.item : null;
        if (!item) return minZoom;
        const itemNodes = 'nodes' in item ? Object.values(item.nodes) as { size: number }[] : [];
        const itemSize = 'size' in item ? item.size : itemNodes.map(({ size }) => size).reduce((a, b) => a + b, 0);
        return scaleZoom(itemSize);
    };

    const setPan = ({ id, x, y, retry }: { id: string, x: number, y: number, retry?: boolean }) => {
        delay(() => {
            const pan = getPanLocation({ id, x, y });
            if (!pan) return;
            setView({
                zoom: pan.zoom,
                offsetX: pan.offsetX,
                offsetY: pan.offsetY
            });
            if (pan.isOffScreen && !retry) {
                setPan({ id, x, y, retry: true });
            }
        }, retry ? 500 : 300);
    };

    const handlePanAndZoom = ({ id, x, y }: { id: string, x: number, y: number }) => {
        if (!chartRef.current) return null;
        if (!id) return null;

        const { width, height } = (chartRef.current as any).containerRef.current.getBoundingClientRect();

        const zoom = getZoomValue({ id });
        const expectedWidth = width * zoom ** -1;
        const coordinates = getCoordinates({ id, x, y });
        if (!coordinates) return;
        const itemWorldCoordinates = chartRef.current.worldCoordinates(coordinates.x, coordinates.y);
        if (!('x' in itemWorldCoordinates)) return;

        const scaleFactor = width / expectedWidth;

        setView({
            zoom,
            offsetX: (-itemWorldCoordinates.x * scaleFactor) + (width / 1.15),
            offsetY: (-itemWorldCoordinates.y * scaleFactor) + (height / 2)
        });

        setPan({ id, x, y });
    };

    const scrollToBottom = () => {
        document.getElementsByClassName('content-page')[0]?.scrollTo({ top: 310, behavior: 'smooth' });
    };

    const handleClick: Chart.onClickHandler = ({ id, x, y }) => {
        scrollToBottom();
        if (!chartRef.current || !chartView || !('zoom' in chartView)) return null;
        if (!id) return null;

        handlePanAndZoom({ id, x, y });

        (tooltipRef.current as any).setTooltip({ id: '', x: 0, y: 0 });
        const ids = getIdToSearch(id);
        if (!ids) return;
        delay(() => {
            setParam(ids.isParent ? 'narrative-theme' : 'narrative', ids.idToSearch);
            dispatch(saveSelectedCluster({
                selectedCluster: ids.idToSearch,
                isParentCluster: ids.isParent
            }));
            (tooltipRef.current as any).setTooltip({ id: '', x: 0, y: 0 });
            setShowSubclusters(true);
        }, 200);
    };

    const handleChange: Chart.onChangeHandler = ({ view, positions: newPositions }) => {
        if (newPositions) {
            setPositions(newPositions);
        }
        if (!view || !('zoom' in view) || view.zoom === 1) return;
        setView(view);
        const { zoom } = view;
        if (toolbarRef.current && !initialChartView) {
            toolbarRef.current.setZoom(zoom);
            setInitialView(view);

            const clusterId = getParam('narrative-theme');
            const hasParam = clusterId ? `_combonode_${clusterId}` : getParam('narrative');
            if (hasParam) {
                handlePanAndZoom({ id: hasParam, x: view.offsetX, y: view.offsetY });
                setShowSubclusters(true);
            }
        }
        if (
            (zoom > zoomThresholdToShowSubClusters && showSubClusters)
            || (zoom <= zoomThresholdToShowSubClusters && !showSubClusters)
        ) return null;
        setShowSubclusters(zoom > zoomThresholdToShowSubClusters);
    };

    const chartZoom = (direction: 'in' | 'out') => {
        scrollToBottom();
        if (!chartRef.current) return null;
        chartRef.current.zoom(direction);
    };

    const resetZoomAndPan = () => {
        scrollToBottom();
        setView(initialChartView);
    };

    return (
        <div className="h-100 overflow-hidden position-relative cursor-default" data-testid="regraph">
            <MemoizedChart chartRef={chartRef}
                items={items}
                onClick={handleClick}
                onCombineNodes={combineNodesHandler}
                onPointerMove={updateTooltip}
                onChange={handleChange}
                showSubClusters={showSubClusters}
                view={chartView}
                onViewChange={(viewOptions) => {
                    const { zoom } = viewOptions as Chart.ViewOptions;
                    if (!toolbarRef.current) return null;
                    toolbarRef.current.setZoom(zoom);
                }}
                positions={positions}
            />
            <Tooltip ref={tooltipRef} clusters={clusters} showSubClusters={showSubClusters} />
            {!selectedCluster && <BubbleChartToolbar chartZoom={chartZoom} ref={toolbarRef} resetZoomAndPan={resetZoomAndPan} />}
        </div>
    );
};

const getPositions = (clusters: ClusterForChartWithSubClusters[]) => Object.fromEntries(
    clusters.filter(({ coordinates }) => coordinates?.x).map(({ id, coordinates }) => [
        `_combonode_${id}`,
        coordinates
    ])
);

type TooltipProps = {
    clusters: ClusterForChartWithSubClusters[]
    showSubClusters: boolean
}

const Tooltip = forwardRef(({ clusters, showSubClusters }: TooltipProps, ref) => {
    const [tooltip, setTooltip] = useState<{ id: string, x: number, y: number }>({ id: '', x: 0, y: 0 });
    const tooltipRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => ({
        setTooltip: ({ id, x, y }: { id: string, x: number, y: number }) => {
            if (id) {
                if (tooltip.x === x && tooltip.y === y) return;
                setTooltip({
                    id: id.replace('_combonode_', ''),
                    x,
                    y
                });
            } else {
                if (tooltip.id === '') return;
                setTooltip({
                    id: '',
                    x,
                    y
                });
            }
        }
    }));
    if (!tooltip.id) return null;
    let cluster = clusters.find(({ id }) => id === tooltip.id);

    const tooltipHeight = tooltipRef.current?.clientHeight || 400;
    const wrapperHeight = tooltipRef.current?.parentElement?.clientHeight || 800;
    const isTooltipOverflow = tooltipHeight + tooltip.y > wrapperHeight;

    const getWrapper = (children: React.ReactNode) => (
        <div className="position-absolute p-2 bg-white pointer-events-none border rounded shadow dont-break-out"
            style={{
                top: isTooltipOverflow ? tooltip.y - tooltipHeight : tooltip.y,
                left: tooltip.x,
                width: 273,
                zIndex: 9999
            }}
            ref={tooltipRef}
        >
            {children}
        </div>
    );
    const allSubClusters = clusters.map(({ subClusters }) => subClusters).flat();
    const subCluster = allSubClusters.find(({ id }) => id === tooltip.id);

    if (!cluster && !showSubClusters) {
        cluster = clusters.find(({ subClusters }) => subClusters.find(({ id }) => id === tooltip.id));
    }
    if (cluster) {
        const sentiment = calculateAverageSentiment(cluster.sentimentJson);
        return getWrapper(
            <div>
                <p>{cluster.title}</p>
                <hr />
                <p>Narratives: {cluster.subClusters.length}</p>
                <p>Content: {cluster.volume}</p>
                <p>Duplicates: {cluster.duplicatedDocsCount}</p>
                <hr />
                <p className="d-flex align-items-center">
                    {getIcons('sentiment', sentiment)}
                    <span className="pl-1 pr-11 text-capitalize">{sentiment}</span> sentiment
                </p>
                {cluster.topActors?.length && <p className="m-0">{cluster.topActors[0].actor}(Top actor)</p>}
            </div>
        );
    }
    if (!showSubClusters || !subCluster) return null;
    const sentiment = calculateAverageSentiment(subCluster.sentimentJson);
    return getWrapper(
        <div>
            <p>{subCluster.subClusterTitle}</p>
            <hr />
            <p>Content: {subCluster.volume}</p>
            <p>Duplicates: {subCluster.duplicatedDocsCount}</p>
            <hr />
            <p className="m-0 d-flex align-items-center">
                {getIcons('sentiment', sentiment)}
                <span className="pl-1 pr-11 text-capitalize">{sentiment}</span> sentiment
            </p>
            {subCluster.topActors?.length && <p className="m-0">{subCluster.topActors[0].actor}(Top actor)</p>}
        </div>
    );
});

interface MemoizedChartProps extends Chart.Props {
    chartRef: React.RefObject<Chart<any, string | number>>
    showSubClusters: boolean
}

const MemoizedChart = React.memo(({
    items,
    onClick,
    chartRef,
    onCombineNodes,
    onPointerMove,
    onChange,
    showSubClusters,
    view,
    onViewChange,
    positions
}: MemoizedChartProps) => (
    <Chart items={items}
        options={{
            backgroundColor: allColors.blue[100],
            navigation: false,
            overview: false,
            selection: {
                color: 'rgba(0,0,0,0)',
                labelColor: showSubClusters ? allColors.darkblue[700] : 'rgba(0,0,0,0)'
            },
            minZoom,
            maxItemZoom: maxZoom,
            combo: {
                autoSelectionStyle: false
            },
            labels: {
                fontFamily: '"Nunito", sans-serif'
            }
        }}
        onClick={onClick}
        ref={chartRef}
        combine={{
            properties: ['clusterId'],
            level: 1
        }}
        view={view}
        onCombineNodes={onCombineNodes}
        onPointerMove={onPointerMove}
        onChange={onChange}
        onViewChange={onViewChange}
        onWheel={({ modifierKeys, preventDefault }) => {
            if (!modifierKeys.ctrl) {
                preventDefault();
            }
        }}
        positions={positions}
    />
), (prevProps, nextProps) => (
    isEqual(prevProps.items, nextProps.items)
    && isEqual(prevProps.view, nextProps.view)
));

type GetSubClusterItemsProps = {
    subClusters: SubClusterForChart[]
    clusterId: string
    scaleDomain: number[]
    showSubClusters: boolean
    notSelected: boolean
    selectedCluster?: string
}
const getSubClusterItems = ({
    subClusters,
    clusterId,
    scaleDomain,
    showSubClusters,
    notSelected,
    selectedCluster
}: GetSubClusterItemsProps) => {
    const scaleSize = scaleLinear().domain(scaleDomain).range([5, 30]);
    const scaleBorder = scaleLinear().domain(scaleDomain).range([2, 0.5]);
    const scaleFontSize = scaleLinear().domain([5, 10, 30]).range([20, 10, 4]);

    return subClusters.map((subCluster) => {
        const sentiment = calculateAverageSentiment(subCluster.sentimentJson) as SentimentName;
        const size = scaleSize(subCluster.volume);
        const subClusterNotSelected = Boolean(selectedCluster && selectedCluster !== subCluster.id);
        let opacity = (notSelected && subClusterNotSelected) ? 0.3 : 1;
        if (!showSubClusters) {
            opacity = 0;
        }
        return ([
            subCluster.id,
            {
                data: {
                    type: 'subCluster',
                    id: subCluster.id,
                    volume: subCluster.volume,
                    duplicatedDocsCount: subCluster.duplicatedDocsCount,
                    clusterId
                },
                size,
                color: applyOpacity(nodeColors[sentiment].node, opacity),
                border: {
                    color: applyOpacity(nodeColors[sentiment].nodeBorder, opacity),
                    width: scaleBorder(subCluster.volume)
                },
                label: {
                    color: applyOpacity('rgb(3, 14, 58)', opacity),
                    text: largeNumber(subCluster.volume),
                    backgroundColor: 'transparent',
                    fontSize: scaleFontSize(size)
                }
            }
        ]);
    });
};

const applyOpacity = (rgb: string, opacity: number) => rgb.replace(')', `,${opacity})`).replace('rgb(', 'rgba(');

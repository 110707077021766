import React from 'react';
import TelegramEmbed from 'react-telegram-embed';
import { InstagramEmbed, XEmbed, FacebookEmbed } from 'react-social-media-embed';
import { TikTok } from './TikTok';
import { UrlSnapshot } from './UrlSnapshot';

type ContentProps = {
    contentType: string;
    threadId: string;
    url: string;
    id: string;
    text: string;
    from: string;
}

type ContentPreviewProps = {
    data: ContentProps;
}

export const ContentPreview = ({ data }: ContentPreviewProps) => {
    const uri = data.url?.replace('/groups', '').replace('/permalink/', '/posts/');
    switch (data.contentType) {
        case 'tweet':
            return (
                <div className="w-100">
                    <XEmbed url={data.url} />
                </div>
            );
        case 'facebook':
            return (
                <div data-testid="facebook-content">
                    <FacebookEmbed url={uri} width="100%" height="285px" />
                </div>
            );
        case 'tiktok':
            return (
                <div data-testid="tiktok-content">
                    <TikTok data={data} />
                </div>
            );
        case 'instagram':
            return (
                <div data-testid="instagram-content">
                    <InstagramEmbed url={data.url} />
                </div>
            );
        case 'telegram':
            return (
                <div data-testid="telegram-content">
                    <TelegramEmbed src={data.url} />
                </div>
            );
        default:
            return <UrlSnapshot data={data} />;
    }
};

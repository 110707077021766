import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CONTENT_TYPE } from '../../utils/contentTypes';
import { RootState } from '../../store';

type SearchFeedbackProps = {
    totalCount: number,
    error: boolean,
    contentType?: string
}

type WithLinkProps = {
    children: React.ReactNode,
    contentType?: string
}

export const SearchFeedback = ({ totalCount, error, contentType = 'content_match' }: SearchFeedbackProps) => {
    const { query: searchPhrase, booleanQuery } = useSelector((state: RootState) => state.filters.filters);

    if (searchPhrase || booleanQuery) {
        if (error) {
            return (
                <p>
                    An error occurred while trying to search for {searchPhrase}.
                </p>
            );
        }
        if (totalCount === 0) {
            return (
                null
            );
        }

        return (
            <p data-testid="showing-results">
                <WithLink>
                    {totalCount} {contentType === CONTENT_TYPE.THREAT ? 'threats' : 'matches'} found
                </WithLink> (
                <span className="highlighted-text">Highlighted</span> information are the search keywords)
            </p>
        );
    }

    if (!totalCount) return null;
    return (
        <p>
            <WithLink contentType={contentType}>
                {totalCount} Total {contentType === CONTENT_TYPE.THREAT ? 'Threats' : 'Matches'}
            </WithLink> (
            <span className="highlighted-text">
                Highlighted
            </span> information are the keywords that are monitored in your situation room)
        </p>
    );
};

const WithLink = ({ children, contentType }: WithLinkProps) => {
    const { pathname } = useLocation();
    const relativePathForMatches = 'overview/matches';
    if (contentType === CONTENT_TYPE.MATCH
        && !pathname?.includes(relativePathForMatches)) return <Link to={relativePathForMatches}>{children}</Link>;
    return <span>{children}</span>;
};
